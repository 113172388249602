@import "../../base.module.scss";

.optionRow {
  @include clearfix;
  margin-bottom: 10px;
}

.optionAlpha {
  @include font-medium;
  float: left;
  font-size: 16px;
  line-height: 26px;
  padding-right: 10px;
}

.optionContent {
  overflow: hidden;
}

.text {
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 9px;
}

.barAndStats {
  @include clearfix;
}

.bar {
  float: left;
  background-color: $polyupaths-green-color;
  margin-top: 4px;
  margin-right: 10px;
  height: 20px;
}

.statsContainer {
  float: left;
}

.countText {
  float: left;
  font-size: 16px;
  line-height: 26px;
}

.optionDiagram {
  margin-top: 9px;
}
