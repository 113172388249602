@import "../../base.module.scss";

.optionRow {
  display: table;
  padding-bottom: 24px;
  &.clickable {
    cursor: pointer;
  }
}

.optionAlphaContainer {
  display: table-cell;
  &.clickable {
    cursor: pointer;
  }
}

.optionAlpha {
  @include font-medium;
  font-size: 24px;
  line-height: 26px;
  padding: 0 10px 0 30px;
  &.clickable {
    background: url("../../images/answer_image_attachment.svg") center left /
      auto 18px no-repeat;
  }
}

.optionContent {
  display: table-cell;
  width: 100%;
  &.clickable {
    cursor: pointer;
  }
}

.text {
  font-size: 24px;
  line-height: 26px;
  padding-bottom: 9px;
}

.barAndStats {
  @include clearfix;
}

.bar {
  float: left;
  background-color: $polyupaths-green-color;
  margin-top: 4px;
  height: 20px;
}

.statsContainer {
  float: left;
}

.percentageText {
  float: left;
  font-size: 20px;
  line-height: 28px;
  color: $polyupaths-green-color;
  padding-left: 10px;
}

.countText {
  float: left;
  font-size: 20px;
  line-height: 28px;
  color: $polyupaths-gray-color4;
  padding: 0 20px 0 10px;
  position: relative;
  &.clickable.expanded:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include make-triangle(top, 10px, 7px, $polyupaths-gray-color4);
  }
  &.clickable.collapsed:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include make-triangle(bottom, 10px, 7px, $polyupaths-gray-color4);
  }
}

.imageModal {
  display: block;
  position: absolute;
  top: 50%;
  width: 781px;
  height: 688px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: white;
}

.modalContent {
  width: 100%;
  height: 100%;
  display: table;
}

.modalUpperContainer {
  @include clearfix;
  padding: 20px;
}

.modalAlphaText {
  @include font-medium;
  float: left;
  font-size: 24px;
  line-height: 26px;
  padding-right: 10px;
}

.modalText {
  overflow: hidden;
  font-size: 24px;
  line-height: 26px;
  padding-right: 44px;
}

.modalCloseButton {
  position: absolute;
  top: 8px;
  right: 5px;
  width: 44px;
  height: 44px;
  background: url("../../images/modal_close.svg") center / 16px auto no-repeat;
  cursor: pointer;
}

.modalImageContainer {
  display: table-row;
  height: 100%;
  position: relative;
}

.participantNameContainer {
  background: url("../../images/participants.svg") 15px 10px / 21px auto
      no-repeat,
    white;
  padding: 9px 37px 12px 42px;
  border-radius: 0 30px 30px 30px;
  border: 2px solid #f2f2f2;
}

.participantNameText {
  color: $polyupaths-black-color;
  font-size: 18px;
  line-height: 25px;
}
