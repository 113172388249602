@import "../../base.module.scss";

.button {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  float: left;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.active {
  background-color: $polyupaths-gray-color2;
}
