@import "../../base.module.scss";

.rootContainer {
  @include clearfix;
  @include layout-desktop {
    padding: 0 50px;
    height: 100px;
  }
  @include layout-mobile {
    padding: 0 20px;
    height: 90px;
  }
}

.leftContainer {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  &::before {
    content: "";
    float: left;
    width: 36px;
    height: 60px;
    background: url("../../images/app_logo.svg") center / cover no-repeat;
  }
}

.templateCodeText {
  @include font-semibold;
  display: block;
  color: $polyupaths-gray-color4;
  line-height: 30px;
  padding-left: 52px;
}

.templateNameText {
  @include font-medium;
  display: block;
  font-size: 18px;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 52px;
}

.rightContainer {
  float: right;
  height: 100%;
  @include layout-desktop {
    max-width: 25%;
  }

  @include layout-mobile {
    max-width: 40%;
  }
  position: relative;
}

.participantNameText {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  padding: 5px 10px 5px 30px;
  background-color: #f2f2f2;
  border-radius: 14.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background: url("../../images/green_participant.svg") center / cover
      no-repeat;
  }
}
