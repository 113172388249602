@import "../../base.module.scss";

.inputLabel {
  display: block;
  @include font-semibold;
  margin-bottom: 18px;
}

.textInputWrapper {
  margin-bottom: 20px;
}

.textInput {
  @include font-regular;
  font-size: $large-body-font-size;
  line-height: 26px;
  padding: 10px 16px;
}
