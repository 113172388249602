@import "../../base.module.scss";

.activityContainer {
  @include clearfix;
  padding-left: 30px;
}

.activityBasicInfo {
  @include clearfix;
  margin-bottom: 33px;
}

.activityName {
  @include font-semibold;
  font-size: 26px;
  line-height: 37px;
  float: left;
}

.activityType {
  float: right;
  font-size: 16px;
  line-height: 22px;
  margin-top: 7px;
}

.teacherAsk {
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6;
  margin-bottom: 10px;
}

.questionDiagram {
  margin-top: 30px;
}

.nameInputTitle {
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6;
  margin-bottom: 10px;
}

.headerContainer {
  @include clearfix;
}

.nameInputContainer {
  width: 60%;
  float: left;
}

.nameInput {
  padding: 8px 5px;
  line-height: 26px;
}

.questionInput {
  font-size: 16px;
  line-height: 26px;
  width: 100%;
  border: none;
}

.typeSelectorContainer {
  float: right;
  height: 46px;
  border-radius: 23px;
  background-color: #ffffff;
  border: 1px solid $polyupaths-gray-color2;
  position: relative;
}

.typeSelectorLabel {
  float: left;
  font-size: $large-body-font-size;
  line-height: 46px;
  margin-left: 20px;
}

.typeSelector {
  @include font-semibold;
  margin-left: 5px;
  margin-right: 20px;
  float: left;
  font-size: 18px;
  border: 0;
  height: 44px;
  background-color: white;

  /* reset */
  appearance: none;
}

.questionContainer {
  margin-bottom: 30px;
}

.editorSupplements {
  @include clearfix;
}

.imageUploadButton {
  float: left;
}

.imageChooser {
  float: left;
}

.questionEditor {
  margin-bottom: 10px;
}

.questionEditorError {
  border: 2px solid $polyupaths-red-color;
  margin-bottom: 10px;
}

.errorText {
  font-size: $smaller-body-font-size;
  color: $polyupaths-red-color;
  letter-spacing: 0.52px;
  margin-top: 5px;
}

.selectOptionText {
  line-height: 44px;
  margin-right: 5px;
  @include single-line-truncate;
}
