@import "../../base.module.scss";

.lockText {
  @include font-semibold;
  color: white;
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  &::before {
    content: "";
    display: block;
    width: 95px;
    height: 141px;
    margin: 0 auto 23px auto;
    background: url("../../images/student_session_lock.svg") center / 95px 141px
      no-repeat;
  }
}
