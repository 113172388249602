@import "../../../base.module.scss";

.formRow {
  @include row;

  margin-top: 65px;
}

.loginTitleContainer {
  @include column-offset(1);
  float: left;
  border-top: 5px solid;
  border-bottom: 5px solid;
  width: 300px;
  height: 130px;
}

.loginTitle {
  @include font-medium;
  padding: 32px 25px 38px 25px;
  font-size: 39px;
  text-align: center;
}

.loginEnTitle {
  @include font-medium;
  padding: 32px 25px 38px 25px;
  font-size: 24px;
}

.loginFormContainer {
  @include column(6);
  @include column-offset(1);

  padding: 60px 0;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
}

.inputRow {
  @include column-offset(1);
  @include column(10);
}

.input {
  display: block;
  height: 42px;
  font-size: $body-font-size;
  padding: 0px 19px;
  border-radius: 22px;
  border: 1px solid #cdcdcd;
}

.inputRow:first-child {
  margin-bottom: 11px;
}

.title {
  @include column-offset(1);
  @include font-semibold;
  font-size: 32px;
  margin-bottom: 34px;
  color: $polyupaths-green-color;
}

.link {
  @include column-offset(1);
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  color: $polyupaths-red-color2;
}

.linkContainer {
  @include column-offset(1);
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;

  .link {
    display: inline;
    margin-left: 2px;
  }
}

.actionButton {
  @include column-offset(1);
  @include column(10);
  @include font-medium;
  font-size: 16px;
  line-height: 44px;
  margin-top: 25px;
  margin-bottom: 38px;
}
