@import "../../base.module.scss";

.titleContainer {
  @include clearfix;
  margin-bottom: 10px;
}

.titleText {
  @include font-semibold;
  float: left;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6;
}

.optionDescriptionText {
  float: right;
  font-size: 16px;
  line-height: 22px;
}

.pollingContainer {
  @include clearfix;
  background-color: #ffffff;
  padding: 10px 25px;
  margin-bottom: 10px;
}
