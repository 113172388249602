@import "../../base.module.scss";

.alertContainer {
  @include clearfix;
  @include font-semibold;
  display: block;
  background-color: white;

  @include layout-desktop {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px 74px 60px 74px;
    width: 779px;
    margin: 0 auto;
  }

  @include layout-mobile {
    width: 352px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 30px 18px 30px 18px;
  }
}

.leftContainer {
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 27px;
  background-image: url(../../images/exclamation.svg);

  @include layout-mobile {
    float: none;
    margin-bottom: 20px;
  }
}

.rightContainer {
  float: left;

  @include layout-mobile {
    float: none;
  }
}

.title {
  font-size: 26px;
  color: $polyupaths-red-color;
}

.message {
  color: black;
  font-size: 16px;
  margin: 20px 0 15px 0;
}

.buttonsContainer {
  @include clearfix;
}

.button {
  @include font-medium;
  font-size: 16px;
  margin-top: 20px;
  padding: 10px 52px 12px 52px;
}

.closeIcon {
  width: 16px;
  height: 17px;
}

.closeButton {
  display: block;
  background-color: white;
  position: absolute;
  width: 56px;
  height: 56px;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
}

.browserList {
  @include font-regular;
  font-size: 16px;
  line-height: 22px;

  @include layout-mobile {
    display: none;
  }
}

.browserVersion {
  color: $polyupaths-gray-color4;
}
