@import "../../base.module.scss";

.headerContainer {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 25px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid $polyupaths-gray-color;
}

.actionsContainer {
  float: left;
  width: 75%;
}

.buttonsContainer {
  float: right;
  width: 25%;
  margin-top: 5px;
}

.inputContainer {
  width: 20%;
  float: left;
  margin-right: 10px;
}

.inputText {
  @include font-regular;
  font-size: $large-body-font-size;
  padding: 4px;
  line-height: 35px;
  float: left;
}

.cancelButton {
  float: right;
  color: $polyupaths-black-color;
  border: 1px solid #cdcdcd;
  padding: 6px 25px;
  line-height: 21px;
  font-size: $smaller-body-font-size;
  @include font-regular;
}

.finishEditTemplateButton {
  float: right;
  background-color: $polyupaths-green-color;
  border: none;
  color: #ffffff;
  margin-left: 15px;
  height: 35px;
  @include hover-background-color-darken($polyupaths-green-color);

  p {
    font-size: $smaller-body-font-size;
    @include font-regular;
  }
}

.selectorContainer {
  float: left;
  position: relative;
  margin-right: 10px;

  &::after {
    content: "";
    @include make-triangle(bottom, 10px, 6px, #333333);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.selector {
  font-size: 16px;
  height: 45px;
  padding: 4px 16px;
  min-width: 200px;
  border-radius: 20px;
  border: 1px solid $polyupaths-gray-color2;
  background-color: #ffffff;

  /* reset */
  appearance: none;
}

.yearSelector {
  min-width: 120px;
}

.noRelevantRecordsText {
  @include horizontal-vertically-center-child-element;
  color: $polyupaths-gray-color4;
  font-size: $large-body-font-size;
  letter-spacing: 0.68px;
}

.selectOptionText {
  line-height: 35px;
  @include single-line-truncate;
}
