@import "../../base.module.scss";

$right-upper-height: 35px;
$right-width: 350px;
$arrows-width: 124px;

.rootContainer {
  @include clearfix;
  // This is derived from the content height of rightContainer;
  height: 127px;
}

.leftContainer {
  @include clearfix;
  margin-right: $right-width;
  height: 100%;
  position: relative;
}

.rightContainer {
  float: right;
  width: $right-width;
}

.rightUpperContainer {
  @include clearfix;
  padding-bottom: 26px;
}

.clearResultButton {
  position: relative;
  float: right;
  margin-left: 13px;
}

.clearResultButtonText {
  @include font-medium;
  font-size: 16px;
  color: $polyupaths-red-color;
  line-height: $right-upper-height;
  padding: 0 18px 0 43px;
  background: url("../../images/teacher_session_clear_result.svg") 14px center /
    auto 16px no-repeat;
}

.widgetContainer {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 0;
}

.widgetUpperContainer {
  @include clearfix;
  position: relative;
}

.widgetUpperLeftContainer {
  @include clearfix;
  margin-right: $arrows-width;
  text-align: left;
  margin-bottom: 10px;
}

.widgetUpperRightContainer {
  position: absolute;
  width: $arrows-width;
  right: 0;
  top: 0;
}

.arrowContainer {
  display: inline-block;
  width: 52px;
  height: 52px;
  border: 1px solid $polyupaths-gray-color2;
  border-radius: 26px;
  position: relative;
  margin-left: 10px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.progressBar {
  height: 7px;
}

.answerCountText {
  display: block;
  float: right;
  line-height: $right-upper-height;
  font-size: 24px;
  padding-left: $right-upper-height + 5px;
  background: url("../../images/participants.svg") left center /
    $right-upper-height $right-upper-height no-repeat;
}

.actionButton {
  @include font-semibold;
  font-size: 27px;
  line-height: 65px;
  width: 100%;
}

.popupButton {
  position: relative;
  font-size: 18px;
  color: $polyupaths-gray-color4;
  cursor: pointer;
}

.popupButtonText {
  vertical-align: middle;
  &::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    @include make-triangle(bottom, 11px, 6px, $polyupaths-gray-color4);
  }
  &.popupListShown::after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    @include make-triangle(top, 11px, 6px, $polyupaths-black-color);
  }
}

.popupList {
  position: absolute;
  left: 0;
  width: 255px;
  background-color: white;
  bottom: 100%;
}

.activityName {
  min-height: 33px;
}
