@import "../../base.module.scss";

.sessionsContainer {
  @include clearfix;
  padding: 37px;
}

.noInProgressSessionsText {
  @include horizontal-vertically-center-child-element;
  color: $polyupaths-gray-color4;
  font-size: $large-body-font-size;
  letter-spacing: 0.68px;
}
