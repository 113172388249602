@import "../../base.module.scss";

.image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.placeholder {
  background-color: #ffffff;
}

.failed {
  background-color: $polyupaths-gray-color;
  border: 1px solid $polyupaths-gray-color2;
  background-image: url(../../images/exclamation_2.svg);
  background-size: initial;
  cursor: pointer;
}
