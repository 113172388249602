@import "./AdminPanel.module.scss";

.headerRow {
  padding: 18px 40px;
  border-bottom: 1px solid $polyupaths-gray-color2;
}

.headerTitle {
  @include font-medium;
  font-size: 18px;
}

.confInputs {
  padding: 20px 40px;
}

.confTitle {
  @include font-medium;
  font-size: 16px;
  margin-bottom: 18px;
}

.confInputContainer {
  margin-bottom: 20px;
}

.confInput {
  @include font-regular;
  height: 46px;
  border: 1px solid #cdcdcd;
  padding: 10px 15px;
  width: 50%;
}

.saveButton {
  @include font-regular;
  padding: 6px 25px;
  line-height: 22px;
  font-size: 16px;
}

.stickerImgs {
  @include clearfix;
  display: block;
  margin-bottom: 19px;
}

.stickerImg {
  float: left;
  margin-right: 20px;
}
