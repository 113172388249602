@import "../../base.module.scss";

.uploadButtonWrapper {
  @include clearfix;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.disabled {
  opacity: 0.5;
}

.buttonContainer {
  border: 1px solid $polyupaths-gray-color2;
  border-radius: 24.5px;
  padding: 7px 16px;
  line-height: 17px;
  cursor: pointer;
  background-color: #ffffff;
  float: left;
}

.disabled .buttonContainer {
  cursor: not-allowed;
}

.buttonImg {
  vertical-align: middle;
  margin-right: 5px;
}

.buttonText {
  font-size: $smaller-body-font-size;
  letter-spacing: 0.52px;
  margin-left: 5px;
  vertical-align: middle;
}

.uploadingContainer .buttonImg,
.uploadingContainer .buttonText {
  visibility: hidden;
}

.uploadButtonWrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 35px;
  opacity: 0;
  cursor: pointer;
}

.error {
  @include font-semibold;
  font-size: $smaller-body-font-size;
  color: $polyupaths-red-color;
  text-align: center;
  float: left;
  margin-left: 5px;
  line-height: 35px;
}
