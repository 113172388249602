@import "../../base.module.scss";

.headerContainer {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 25px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid $polyupaths-gray-color;
}

.breadcrumbsContainer {
  float: left;
}

.arrowIcon {
  float: left;
  margin-top: 7px;
}

.breadcrumbsText {
  @include font-medium;
  font-size: $large-body-font-size;
  padding: 6px 10px;
  line-height: 23px;
  float: left;
}

.templateYear {
  color: $polyupaths-green-color;
}

.templateName {
  color: $polyupaths-red-color;
}
