$gutter: 20px;
$total-column: 12;
$content-min-width: 1024px;
$content-max-width: 1200px;

@mixin clearfix {
  &::before {
    display: table;
    content: "";
    clear: both;
  }
  &::after {
    display: table;
    content: "";
    clear: both;
  }
}

@mixin content-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: $gutter / 2;
  padding-right: $gutter / 2;
  min-width: $content-min-width;
  max-width: $content-max-width;
  @include clearfix;
}

@mixin row {
  margin-left: $gutter / -2;
  margin-right: $gutter / -2;
  @include clearfix;
}

@mixin column($columns) {
  @include column-width($columns);
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

@mixin column-offset($columns) {
  // $num-column should be in range [0, 12];
  margin-left: percentage(($columns / $total-column));
}

@mixin column-width($columns) {
  // $num-column should be in range [0, 12];
  width: percentage(($columns / $total-column));
}

@mixin min-column-width($columns) {
  // $num-column should be in range [0, 12];
  min-width: percentage(($columns / $total-column));
}

@mixin max-column-width($columns) {
  // $num-column should be in range [0, 12];
  max-width: percentage(($columns / $total-column));
}

/* stylelint-disable */
:export {
  contentminwidth: $content-min-width;
}
/* stylelint-enable */
