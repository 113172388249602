@import "../../base.module.scss";

$height: 75px;

.rowContainer {
  position: relative;
  @include clearfix;
  height: $height;
  border-bottom: 1px solid $polyupaths-gray-color;
  padding: 0 40px;
}

.dateTimeText {
  float: left;
  line-height: $height;
  font-size: 12px;
  @include font-medium;
  width: 12.5%;
}

.templateCodeText {
  float: left;
  line-height: $height;
  font-size: 14px;
  @include font-medium;
  @include single-line-truncate;
  width: 7%;
  padding-right: 10px;
}

.templateNameText {
  float: left;
  line-height: $height;
  font-size: 14px;
  @include font-medium;
  @include single-line-truncate;
  width: 21%;
  padding-right: 10px;
}

.schoolNameText {
  float: left;
  line-height: $height;
  font-size: 14px;
  @include font-medium;
  @include single-line-truncate;
  width: 27%;
  min-height: 1px;
  padding-right: 10px;
}

.participantCountText {
  float: left;
  line-height: $height;
  font-size: 14px;
  text-align: center;
  @include font-medium;
  width: 8%;
}

.viewButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 90px;
}

.viewButtonText {
  font-size: 14px;
  line-height: 34px;
  padding: 0 17px 0 45px;
  background: url("../../images/template_activities.svg") 17px center / 16px
    auto no-repeat;
}

.moreButton {
  position: absolute;
  width: 44px;
  height: 44px;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}

.moreActionsPopupMenu {
  position: absolute;
  right: 62px;
  top: 0;
  z-index: $zIndex-popup;
  background-color: #ffffff;
  border: 1px solid $polyupaths-gray-color;
}

.downloadRecordButton {
  color: $polyupaths-orange-color;
  border-radius: 0;
  border: 0;
  height: auto;
}

.deleteRecordButton {
  color: $polyupaths-red-color;
  border-radius: 0;
  border: 0;
  height: auto;
}
