@import "./AdminPanel.module.scss";

.headerRow1 {
  @include clearfix;
  padding: 18px 40px;
}

.headerRow2 {
  @include clearfix;
  padding: 5px 40px 23px 40px;
}

.headerTitle {
  float: left;
}

.headerTitleText {
  @include font-medium;
  font-size: 18px;
  margin-right: 5px;
}

.headerCountText {
  @include font-medium;
  font-size: 18px;
  color: $polyupaths-gray-color4;
}

.unitTemplateSelect {
  float: left;
  width: 27%;
  height: 34px;
  margin-right: 10px;
}

.templateOptionText {
  line-height: 34px;
  @include single-line-truncate;
}

.schoolSelect {
  float: left;
  width: 27%;
  height: 34px;
}

.schoolOptionText {
  line-height: 34px;
  @include single-line-truncate;
}

.fromDatePicker {
  float: right;
  width: 17%;
  min-width: 180px;
  height: 34px;
  margin-right: 10px;
}

.toDatePicker {
  float: right;
  width: 17%;
  min-width: 180px;
  height: 34px;
}

.downloadAllButton {
  float: right;
}

.downloadAllButtonText {
  line-height: 34px;
  padding: 0 15px 0 44px;
  background: url("../../images/download.svg") 20px center / 14px auto no-repeat;
}
