@import "../../base.module.scss";

$height: 84px;

.headerContainer {
  height: $height;
  background-color: #ffffff;
  border-bottom: 1px solid $polyupaths-gray-color;
  padding-left: 40px;
}

.arrowIcon {
  float: left;
  margin-top: 32.5px;
}

.recordsManagementLinkText {
  float: left;
  @include font-medium;
  font-size: 18px;
  color: $polyupaths-orange-color;
  padding: 0 20px;
  line-height: $height;
}

.currentLinkText {
  float: left;
  @include font-medium;
  font-size: 18px;
  color: $polyupaths-black-color;
  padding: 0 20px;
  line-height: $height;
}
