@import "../../base.module.scss";

$desktop-form-padding-right: 53px;
$desktop-form-howtojoin-width: 400px;
$input-height: 44px;

.background {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../../images/student_background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.contentContainer {
  position: relative;
  padding-bottom: 60px;
}

.header {
  position: relative;
  @include clearfix;
  @include layout-desktop {
    margin: 0 auto;
    max-width: $content-max-width;
    padding: 23px $gutter 0 $gutter;
  }
}

.logoImage {
  float: left;
  display: block;
  @include layout-mobile {
    width: 40px;
    height: 67px;
    margin: 17px 0 0 20px;
  }
  @include layout-desktop {
    width: 75px;
    height: 128px;
  }
}

.titleText {
  font-size: 28px;
  text-align: center;
  letter-spacing: 4px;
  @include font-medium;
  @include layout-mobile {
    clear: both;
    padding: 21px 0 0 0;
  }
  @include layout-desktop {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.enTitleText {
  font-size: 28px;
  text-align: center;
  @include font-medium;
  @include layout-mobile {
    clear: both;
    padding: 21px 0 0 0;
  }
  @include layout-desktop {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.localeSwitch {
  float: right;
  @include layout-mobile {
    margin: 34px 21px 0 0;
  }
}

.main {
  @include clearfix;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.17);
  position: relative;
  @include layout-desktop {
    margin: 60px auto 0 auto;
    width: 980px;
  }
  @include layout-mobile {
    margin: 26px 20px 0 20px;
  }
}

.formContainer {
  overflow: hidden;
  height: 100%;

  @include layout-desktop {
    padding: 86px ($desktop-form-padding-right + $desktop-form-howtojoin-width) 120px
      83px;
  }
  @include layout-mobile {
    padding: 43px 25px 60px 25px;
  }
}

.formTitle {
  @include font-semibold;
  font-size: 32px;
  color: $polyupaths-green-color;
  padding: 0 0 30px 0;
}

@mixin input-style {
  display: block;
  font-size: 16px;
  height: $input-height;
  padding: 0px 19px;
  border-radius: 22px;
  border: 1px solid #cdcdcd;
}

.participationInput {
  @include input-style;
  @include layout-mobile {
    // account for the help button
    padding: 0 $input-height 0 19px;
  }
}

.participationInputContainer {
  position: relative;
  display: block;
  width: 100%;
  @include layout-desktop {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: -($desktop-form-padding-right);
      @include make-triangle(left, 25px, 40px, $polyupaths-gray-color3);
    }
  }
}

.participationInputHelpButton {
  @include layout-desktop {
    display: none;
  }
  @include layout-mobile {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: $input-height;
    text-align: center;
    line-height: $input-height;
    color: #fb4c69;
    cursor: pointer;
  }
}

.nameInput {
  @include input-style;
  margin: 11px 0 0 0;
}

.joinButton {
  @include font-medium;
  font-size: 16px;
  line-height: 44px;
  display: block;
  width: 100%;
  margin: 25px 0 0 0;
}

.howToJoinContainer {
  @include layout-desktop {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $desktop-form-howtojoin-width;
    background-color: $polyupaths-gray-color3;
  }
  @include layout-mobile {
    display: none;
  }
}

.howToJoinText {
  text-align: center;
  padding: 77px 0 32px 0;
}

.howToJoinImage {
  width: 324px;
  height: 233px;
  margin: 0 auto;
  background: url("../../images/student_howtojoin.png") center / cover no-repeat;
}

.howToJoinModalContent {
  @include layout-mobile {
    display: block;
    max-width: 352px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: white;
    padding: 62px 18px 68px 18px;
  }
}

.howToJoinModalTitleText {
  text-align: center;
  font-size: 16px;
  padding: 0 0 36px 0;
}

.howToJoinModalImage {
  width: 318px;
  height: 225px;
  margin: 0 auto;
  background: url("../../images/student_howtojoin.png") center / cover no-repeat;
}

.howToJoinModalCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  background: url("../../images/modal_close.svg") center / 18px 18px no-repeat;
}
