@import "../../base.module.scss";

.teacherPortal {
  margin: 0 20px 10px 0;
}

.adminPortal {
  margin: 0 0 10px 0;
}

.activityContainer {
  @include row;
  padding: 10px 15px;
  margin-left: 0;
  margin-right: 20px;
  margin-bottom: 10px;
  border: 1px solid $polyupaths-gray-color2;
  cursor: pointer;
}

.teacherSessionContainer {
  max-height: 240px;
  overflow: scroll;
}

.teacherSession {
  margin: 0;
}
