@import "../../base.module.scss";

.pollingHeader {
  @include clearfix;
  margin-bottom: 10px;
}

.pollingOptionsTitleText {
  float: left;
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6px;
}

.pollingOptionsCheckboxDescriptionText {
  float: right;
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
}

.editPollingOptionsHeaderWrapper {
  @include clearfix;
  position: relative;
  margin-bottom: 20px;
}

.editPollingOptionsTitle {
  float: left;
}

.editPollingOptions {
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6px;
  margin-bottom: 5px;
}

.editPollingOptionsHint {
  @include font-semibold;
  color: $polyupaths-gray-color2;
  font-size: $small-body-font-size;
  line-height: 17px;
  letter-spacing: 0.45px;
}

.editPollingCheckboxGroup {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.editPollingCheckbox {
  float: left;
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6px;
  margin-left: 20px;

  input[type="checkbox"] {
    margin-right: 6px;
    vertical-align: middle;
  }
}

.pollingOptionContainer {
  @include clearfix;
  background-color: #ffffff;
  padding: 10px 25px;
  margin-bottom: 10px;
}

.pollingOptionText {
  font-size: 16px;
  line-height: 26px;
}

.optionDiagram {
  margin-top: 9px;
}

.optionInputWrapper {
  @include clearfix;
  padding-bottom: 20px;
  border-bottom: 1px solid $polyupaths-gray-color2;
  margin-bottom: 20px;
}

.optionInputIdx {
  float: left;
  width: 5%;
  @include font-semibold;
  font-size: 20px;
  line-height: 46px;
}

.pollingOptionContainer .optionInputIdx {
  font-size: 16px;
  line-height: 26px;
}

.optionInputValue {
  width: 95%;
  float: left;
}

.optionInput {
  font-size: $large-body-font-size;
  padding: 8px 5px;
  line-height: 26px;
  width: 100%;
  display: block;
  margin-bottom: 13px;
}

.addOption {
  margin-bottom: 30px;
  color: $polyupaths-black-color;
  border: none;
  padding: 0;
}

.editorSupplements {
  @include clearfix;
}

.imageUploadButton {
  float: left;
}

.imageChooser {
  float: left;
}

.removeOption {
  float: right;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.52px;
  color: $polyupaths-black-color;
  border: none;
  padding: 0;
}
