@import "../../base.module.scss";

.rootContainer {
  @include clearfix;
  position: relative;
}

.leftContainer {
  overflow: hidden;
}

.leftContainerWithRightButtons {
  // 250px reserved for rightContainer
  margin-right: 250px;
}

.rightContainer {
  @include clearfix;
  position: absolute;
  top: 0;
  right: 0;
}

.sessionCodeContainer {
  display: block;
  border: 1px solid #cdcdcd;
  border-radius: 9px;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  float: left;
}

.logo {
  float: left;
  width: 58px;
  height: 98px;
  margin-right: 16px;
}

.templateCodeText {
  display: block;
  font-size: 16px;
  color: #8e8e8e;
}

.templateNameText {
  margin: 10px 0;
}

.sessionCodeText {
  @include font-semibold;
  color: #fb4c69;
}

.pauseButton {
  display: inline-block;
  position: relative;
}

.endButton {
  margin-left: 25px;
}

.pauseButtonToolip {
  @include font-medium;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%) translateY(40px);
  width: 195px;
  padding: 24px 29px;
  font-size: 18px;
  color: $polyupaths-orange-color;
  border: 1px solid $polyupaths-gray-color2;
  border-radius: 49px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  z-index: $zIndex-popup;

  &::before {
    @include make-triangle(top, 27px, 19px, $polyupaths-gray-color2);
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -13.5px;
    margin-top: -19px;
  }

  &::after {
    @include make-triangle(top, 25px, 17px, white);
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -12.5px;
    margin-top: -16.5px;
  }
}
