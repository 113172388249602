@import "../../base.module.scss";

.canvasBackground {
  position: relative;
  overflow: hidden;
  background-color: black;
  height: 100%;

  &.imageLoading:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: $polyupaths-green-color;
    animation: spinner 0.6s linear infinite;
  }
}

.canvasContainer {
  transform-origin: top left;
  position: absolute;
}

@keyframes spinner {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

.textAreaContainer {
  background-color: $polyupaths-green-color;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;

  @include layout-desktop {
    padding: 17px 20px;
  }

  @include layout-mobile {
    padding: 8px 10px;
  }

  &.inactive {
    opacity: 0;
    z-index: -999;
  }
}

.textArea {
  @include font-medium;
  display: block;
  font-size: 18px;
  width: 100%;
  line-height: 26px;
  padding-left: 12px;
  padding-right: 12px;
  /* stylelint-disable */
  resize: none;
  /* stylelint-enable */
}
