@import "../../base.module.scss";

$page-item-height: 30px;

.container {
  @include clearfix;
  padding: 0 $admin-dashboard-horizontal-padding;
  height: $admin-dashboard-footer-height;
}

.pagesContainer {
  float: right;
  padding: ($admin-dashboard-footer-height - $page-item-height) / 2 0;
}

.pageNav {
  display: block;
  float: left;
  padding: 5px 10px;
  border: 1px solid $polyupaths-gray-color2;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
}

.ellipsis {
  display: block;
  float: left;
  padding: 5px 0;
  margin-left: 10px;
}

.currentPage {
  background-color: $polyupaths-green-color;
  border-color: $polyupaths-green-color;
  color: #ffffff;
  @include hover-background-color-darken($polyupaths-green-color);
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
