@import "../../base.module.scss";

.createCustomTemplateModalRow {
  @include clearfix;
  margin-top: 20px;
  margin-bottom: 30px;
}

.modalRowDisplayCode {
  @include column-width(2);
  @include font-medium;
  @include single-line-truncate;
  float: left;
  font-size: $large-body-font-size;
  line-height: 44px;
}

.textInputWrapper {
  @include column-width(10);
  float: left;
}

.modalRowInput {
  @include font-regular;
  font-size: $large-body-font-size;
  line-height: 20px;
  padding: 10px 16px;
}
