@import "../../base.module.scss";

$icon-wrapper-size: 65px;
$orange: #f8ab45;
$red: #f0453a;

.button {
  border: 0;
  cursor: pointer;
  background-color: white;
}

.iconWrapper {
  &.end {
    border: 1px solid #cdcdcd;
  }
  &.pause {
    border: 1px solid #cdcdcd;
  }
  &.paused {
    background-color: $orange;
  }

  display: block;
  width: $icon-wrapper-size;
  height: $icon-wrapper-size;
  margin: 0 auto;
  position: relative;
  border-radius: $icon-wrapper-size / 2;
}

.icon {
  &.end {
    width: 24px;
    height: 24px;
  }
  &.pause {
    width: 25px;
    height: 38px;
  }
  &.paused {
    width: 25px;
    height: 36px;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.label {
  &.end {
    color: $red;
  }
  &.pause {
    color: $orange;
  }
  &.paused {
    color: $orange;
  }

  @include font-semibold;
  display: block;
  text-align: center;
  font-size: 16px;
  padding-top: 6px;
}
