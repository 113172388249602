@import "../../base.module.scss";

$tabBarItemHeight: 56px;
$tabBarItemTextLineHeight: 22px;
$tabBarItemBackgroundColorEven: $polyupaths-red-color;
$tabBarItemBackgroundColorOdd: $polyupaths-orange-color;

.tabBar {
  @include row;
  position: relative;
}

@mixin tabBarItem($backgroundColor) {
  height: $tabBarItemHeight;
  display: block;
  float: left;
  position: relative;
  z-index: 0;
  background-color: $backgroundColor;
  cursor: pointer;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
}

.tabBarItemEven {
  @include tabBarItem($tabBarItemBackgroundColorEven);
}

.tabBarItemOdd {
  @include tabBarItem($tabBarItemBackgroundColorOdd);
}

.tabBarWidth1of4 {
  @include column-width(3);
}

.tabBarWidth1of6 {
  @include column-width(2);
}

.tabBarItemActive {
  z-index: 2;
}

.tabBarItemText {
  @include font-semibold;
  color: white;
  font-size: $body-font-size;
  letter-spacing: 1px;
  line-height: $tabBarItemTextLineHeight;
  margin-top: ($tabBarItemHeight - $tabBarItemTextLineHeight)/2;
  text-align: center;
}

.tabBarItemMultipleText {
  @include font-semibold;
  color: white;
  font-size: $body-font-size;
  letter-spacing: 1px;
  line-height: 18px;
  padding: 6px 20px 10px 20px;
}

@mixin bottomBar($backgroundColor) {
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 0;
  background-color: darken($backgroundColor, 5%);
}

.bottomBarEven {
  @include bottomBar($tabBarItemBackgroundColorEven);
}

.bottomBarOdd {
  @include bottomBar($tabBarItemBackgroundColorOdd);
}
