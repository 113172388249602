@import "../../base.module.scss";

.editorContainer {
  background-color: #ffffff;
  padding: 10px 14px;
  height: 120px;
  overflow: auto;

  &.readOnly {
    padding: 10px 25px;
    height: initial;
  }

  &.editable {
    cursor: text;
  }

  span {
    line-height: 26px;
  }

  ol,
  ul {
    margin: 0 0 16px 0;
    padding: 0;
  }

  li {
    margin-left: 20px;
  }

  blockquote {
    border-left: 5px solid $polyupaths-gray-color;
    font-style: italic;
    margin: 0 0 16px 0;
    padding: 0 0 0 10px;
  }
}

.formatButtons {
  @include clearfix;
  background-color: $polyupaths-gray-color;
  padding: 4px 6px;
  position: relative;
}

.buttonBold {
  @include font-semibold;
  line-height: 26px;
}

.buttonUnderline {
  text-decoration: underline;
  line-height: 26px;
}

.buttonItalic {
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
  line-height: 26px;
}

.separator {
  margin-top: 5px;
  margin-right: 12px;
  width: 1px;
  height: 16px;
  float: left;
  background-color: $polyupaths-gray-color4;
}

.buttonUl {
  position: relative;
  top: 5px;
}

.buttonOl {
  position: relative;
  top: 4px;
}

.buttonBlockQuote {
  position: relative;
  top: 5px;
}
