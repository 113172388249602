@import "../../base.module.scss";

.headerRow {
  @include clearfix;
  position: relative;
  margin-bottom: $header-margin-bottom;
}

.logo {
  float: left;
  @include column-width(1);
  height: 135px;
  background: url("../../images/app_logo.svg") center / auto 135px no-repeat;
}

.titleText {
  font-size: 28px;
  text-align: center;
  @include font-medium;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  letter-spacing: 4px;
}

.enTitleText {
  font-size: 28px;
  text-align: center;
  @include font-medium;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.localeSwitch {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
