@import "./AdminPanel.module.scss";

.headerRow1 {
  @include clearfix;
  padding: 18px 40px;
}

.headerRow2 {
  @include clearfix;
  padding: 5px 40px 23px 40px;
}

.headerTitle {
  float: left;
}

.headerTitleText {
  @include font-medium;
  font-size: 18px;
  margin-right: 5px;
}

.headerCountText {
  @include font-medium;
  font-size: 18px;
  color: $polyupaths-gray-color4;
}

.actorSelect {
  float: left;
  width: 27%;
  height: 34px;
  margin-right: 10px;
}

.actorOptionText {
  line-height: 34px;
}

.schoolSelect {
  float: left;
  width: 27%;
  height: 34px;
}

.schoolOptionText {
  line-height: 34px;
  @include single-line-truncate;
}

.fromDatePicker {
  float: right;
  width: 17%;
  min-width: 180px;
  height: 34px;
  margin-right: 10px;
}

.toDatePicker {
  float: right;
  width: 17%;
  min-width: 180px;
  height: 34px;
}
