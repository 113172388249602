@import "../../base.module.scss";

.main {
  width: 100%;
  height: 100%;
  padding-top: 19px;
  background-image: url(../../images/admin_background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  // if not position absolute,
  // will see white background color while window is small and scroll
  position: absolute;
  overflow: auto;
}
