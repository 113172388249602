@import "../../base.module.scss";

.createTemplateButtonContainer {
  float: left;
}

.customTemplateIconButton {
  background-color: #4b6add;
  border: none;
  @include hover-background-color-darken(#4b6add);
}
