@import "../../base.module.scss";

$height: 75px;

.rowContainer {
  position: relative;
  @include clearfix;
  height: $height;
  border-bottom: 1px solid $polyupaths-gray-color;
  padding: 0 40px;
}

.dateTimeText {
  float: left;
  line-height: $height;
  font-size: 12px;
  @include font-medium;
  width: 12.5%;
}

.actor {
  float: left;
  line-height: $height;
  font-size: 14px;
  @include font-medium;
  @include single-line-truncate;
  width: 37.5%;
  padding-right: 10px;
  min-height: 1px;
}

.action {
  float: left;
  line-height: $height;
  font-size: 14px;
  @include font-medium;
  @include single-line-truncate;
  width: 50%;
}
