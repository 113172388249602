@import "../../base.module.scss";

$root-container-padding: 26;
$vertical-padding: 35;
$horizontal-padding: 50;

$polling-margin-between-question-and-options: 30;

$polling-option-image-margin-left: 14;
$polling-option-image-margin-right: 25;
$polling-option-image-width: 210;
$polling-option-image-height: 160;
$polling-option-alpha-size: 48;

.grayRectangleContainer {
  padding: $root-container-padding * 1px 0;
}

.grayRectangle {
  background-color: $polyupaths-gray-color3;
  border-radius: $horizontal-padding * 1px;
  padding: $vertical-padding * 1px $horizontal-padding * 1px;
}

.grayRectangleContent {
  @include clearfix;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  // -webkit-scrollbar is supported by Chrome, Safari, Android and iOS
  // but not supported by IE and Firefox
  // Setting -webkit-scrollbar styles is to force your webpage to show
  // horizontal or vertical scrollbars
  &::-webkit-scrollbar {
    appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.questionImage {
  display: block;
  float: left;
}

.qaWithImageText {
  overflow: hidden;
  padding-left: 30px;
  font-size: 30px;
}

.qaWithoutImageText {
  font-size: 36px;
}

.pollingQuestionContainer {
  @include clearfix;
}

.pollingQuestionWithoutImageText {
  font-size: 36px;
}

.pollingQuestionWithImageText {
  overflow: hidden;
  padding-left: 30px;
  font-size: 24px;
}

.pollingOptionsContainer {
  padding-top: $polling-margin-between-question-and-options * 1px;
}

.pollingOptionContainer {
  @include clearfix;
  margin-bottom: $polling-margin-between-question-and-options * 1px;
}

.pollingOptionAlpha {
  @include font-medium;
  float: left;
  font-size: 28px;
  color: white;
  background-color: $polyupaths-green-color;
  width: $polling-option-alpha-size * 1px;
  height: $polling-option-alpha-size * 1px;
  border-radius: $polling-option-alpha-size * 0.5px;
  line-height: $polling-option-alpha-size * 1px;
  text-align: center;
}

.pollingOptionContentContainer {
  margin-left: ($polling-option-alpha-size + $polling-option-image-margin-left) *
    1px;
}

.pollingOptionContentContainerWidthImage {
  margin-left: (
      $polling-option-alpha-size + $polling-option-image-margin-left +
        $polling-option-image-width + $polling-option-image-margin-right
    ) * 1px;
}

.pollingOptionText {
  font-size: 26px;
  line-height: 48px;
  text-align: initial;
}

.pollingOptionContentContainerWidthImage .pollingOptionText {
  // minor adjust text position to align with image
  position: relative;
  top: -10px;
}

.pollingOptionImage {
  float: left;
  margin-left: $polling-option-image-margin-left * 1px;
  margin-right: $polling-option-image-margin-right * 1px;
}

.whiteboardWithoutImageText {
  font-size: 36px;
}

.whiteboardWithImageText {
  display: block;
  overflow: hidden;
  padding-left: 30px;
  font-size: 30px;
}

/* stylelint-disable */
:export {
  contentcontainerverticalpadding: $vertical-padding;
  rootcontainerpadding: $root-container-padding;
  pollingmarginbetweenquestionandcontent: $polling-margin-between-question-and-options;
}
/* stylelint-enable */
