@import "../../base.module.scss";

$circle-button-size: 29px;

.localeOptionsContainer {
  list-style-type: none;
  overflow: hidden;
}

.localeOption {
  float: right;
  margin-left: 13px;
  font-size: 14px;
  line-height: $circle-button-size;
  height: $circle-button-size;
  width: $circle-button-size;
  text-align: center;
  cursor: pointer;

  &.active {
    border-radius: $circle-button-size / 2;
  }
  &.header {
    color: $polyupaths-gray-color5;
  }
  &.header.active {
    background-color: #38f4b0;
  }
  &.footer {
    color: white;
  }
  &.footer.active {
    color: $polyupaths-gray-color5;
    background-color: white;
  }
}
