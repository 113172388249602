@import "../../base.module.scss";

$row-line-height: 43px;
$edit-button-height: 36px;

.adminUserContainer {
  @include clearfix;
  padding: 16px $admin-dashboard-horizontal-padding;
  border-bottom: 1px solid $polyupaths-gray-color2;
  position: relative;
}

.name {
  float: left;
  width: 50%;
  padding-right: 10px;
  line-height: $row-line-height;
  min-height: $row-line-height;
  @include font-medium;
  @include single-line-truncate;
}

.email {
  float: left;
  width: 25%;
  padding-right: 10px;
  overflow: hidden;
  line-height: $row-line-height;
  font-size: $smaller-body-font-size;
  @include font-medium;
  @include single-line-truncate;
}

.type {
  float: left;
  width: 10%;
  overflow: hidden;
  line-height: $row-line-height;
  font-size: $smaller-body-font-size;
  @include font-medium;
}

.editButtonContainer {
  float: right;
  width: 90px;
  height: $edit-button-height;
  margin-top: ($row-line-height - $edit-button-height) / 2;
  margin-right: 30px;
}

.editButton {
  background-color: $polyupaths-green-color;
  border: none;
  @include hover-background-color-darken($polyupaths-green-color);
}

.moreButton {
  position: absolute;
  padding: 5px 9px 6px 9px;
  top: 19px;
  right: 31px;
}

.moreButtonIcon {
  position: relative;
  top: 3px;
}

.moreActionsPopupMenu {
  position: absolute;
  right: 45px;
  top: 15px;
  z-index: $zIndex-popup;
  background-color: #ffffff;
  border: 1px solid $polyupaths-gray-color;
}

.popupMenuItem {
  color: $polyupaths-black-color;
  border-radius: 0;
  border: 0;
  padding: 15px 17px;
  height: auto;
}
