@import "../../base.module.scss";

.richContentContainer {
  span {
    line-height: 26px;
  }

  ol,
  ul {
    margin: 0 0 16px 0;
    padding: 0;
  }

  li {
    margin-left: 40px;
  }

  blockquote {
    border-left: 5px solid $polyupaths-gray-color;
    font-style: italic;
    margin: 0 0 16px 0;
    padding: 0 0 0 10px;
  }
}
