@import "../../base.module.scss";

$button-height: 34px;
$header-elems-margin-top: 19px;

.headerRow {
  @include row;
  margin-bottom: $header-margin-bottom;
}

.titleContainer {
  @include column-width(4);
  float: left;
  margin-top: $header-elems-margin-top;
}

.title {
  @include font-semibold;
  font-size: $larger-body-font-size;
  letter-spacing: 4px;
}

.enTitle {
  @include font-semibold;
  font-size: $larger-body-font-size;
}

.subTitle {
  @include font-semibold;
  font-size: $large-body-font-size;
  color: $polyupaths-green-color;
}

.email {
  @include font-medium;
  @include column(4);
  @include column-offset(2);
  font-size: $body-font-size;
  letter-spacing: 1px;
  text-align: right;
  line-height: $button-height;
  margin-top: $header-elems-margin-top;
}

.button {
  @include column(1);
  font-size: $smaller-body-font-size;
  color: $polyupaths-black-color;
  height: $button-height;
  line-height: $button-height;
  padding: 0 10px;
  margin-top: $header-elems-margin-top;
}

.logo {
  float: left;
  @include column-width(1);
  height: 100px;
  background: url("../../images/app_logo.svg") center left / 58px auto no-repeat;
}
