@import "../../base.module.scss";

.templateContainer {
  @include row;
  background-color: $polyupaths-gray-color3;
  border-top: 8px solid $polyupaths-green-color;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
}

.activitiesContainer {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 28px 30px 0;
}

.activitiesPanel {
  @include column(3);
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid $polyupaths-gray-color;
}

.activityDetailPanel {
  @include column(9);
  padding-left: 0;
  padding-right: 0;
  left: -1px;
  border-left: 1px solid $polyupaths-gray-color;
}

.activitiesTitle {
  @include font-semibold;
  letter-spacing: 0.6px;
  margin-bottom: 18px;
}

.addActivity {
  height: initial;
  color: $polyupaths-black-color;
  border: none;
  padding: 0;
  margin-bottom: 10px;
}
