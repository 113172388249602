@import "../../base.module.scss";

.rootContainer {
  position: relative;
}

.arrowContainer {
  position: absolute;
  padding: 0 60px;
  top: 0;
  bottom: 0;

  &.left {
    left: 0;
    transform: translateX(-100%);
  }
  &.right {
    right: 0;
    transform: translateX(100%);
  }

  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
