@import "../../base.module.scss";

.button {
  position: relative;
  border: none;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
}

.loadingPNGGradient {
  opacity: 0.7;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    animation: spin 1s infinite linear;
    width: 20px;
    height: 20px;
    background: url("../../images/button_loading_indicator.png") center / cover
      no-repeat;
  }
}

.loadingPureCSS {
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    transform: translateX(-50%) translateY(-50%);
    animation: spin 1s infinite linear;
  }
}

@keyframes spin {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}
