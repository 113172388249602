@import "../../base.module.scss";

.background {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #cbf3ff, #f6fdff) #cbf3ff;
}

.contentContainer {
  position: relative;
  background-color: white;
  @include layout-desktop {
    min-width: $content-min-width;
    max-width: $content-max-width;
    margin: 0 auto;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
  }
}
