@import "../../base.module.scss";

.activityContainer {
  @include clearfix;
  padding-left: 30px;
}

.activityBasicInfo {
  @include clearfix;
  margin-bottom: 33px;
}

.questionContainer {
  margin-bottom: 30px;
}

.activityName {
  @include font-semibold;
  font-size: 26px;
  line-height: 37px;
  float: left;
}

.activityType {
  float: right;
  font-size: 16px;
  line-height: 22px;
  margin-top: 7px;
}

.teacherAsk {
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6;
  margin-bottom: 10px;
}

.questionDiagram {
  margin-top: 30px;
}
