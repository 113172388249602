@import "../../base.module.scss";

$actions-row-height: 34px;

.countText {
  font-size: $large-body-font-size;
  letter-spacing: 1px;
  line-height: 25px;
  color: $polyupaths-black-color;
  padding-left: $admin-dashboard-horizontal-padding;
  margin-top: 18px;
  margin-bottom: 10px;
}

.countNumberText {
  color: $polyupaths-gray-color4;
}

.actionsRow {
  @include clearfix;
  padding: 0 $admin-dashboard-horizontal-padding;
  margin-bottom: 33px;
}

.searchInputContainer {
  float: left;
  height: $actions-row-height;
  border-radius: 22px;
  border: 1px solid #cdcdcd;
  padding: 5px 15px;
}

.searchIcon {
  vertical-align: baseline;
}

.searchInput {
  line-height: 22px;
  border: 0;
  padding: 0 0 0 10px;
  font-size: 16px;
  width: 285px;
}

.addButtonContainer {
  float: right;
  width: 188px;
  height: $actions-row-height;
  margin-right: 10px;
}

.addButton {
  background-color: $polyupaths-blue-color;
  border: none;
  padding: 7px 18px;
  height: $actions-row-height;
  @include hover-background-color-darken($polyupaths-blue-color);
}

.selectorContainer {
  float: left;
  position: relative;
  margin-right: 10px;

  &::after {
    content: "";
    @include make-triangle(bottom, 10px, 6px, #333333);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.selector {
  font-size: 16px;
  height: $actions-row-height;
  padding: 4px 16px;
  min-width: 215px;
  border-radius: 20px;
  border: 1px solid $polyupaths-gray-color2;
  background-color: #ffffff;

  /* reset */
  appearance: none;
}

.selectOptionText {
  line-height: 24px;
  @include single-line-truncate;
}

.noRelevantRecordsText {
  @include horizontal-vertically-center-child-element;
  color: $polyupaths-gray-color4;
  font-size: $large-body-font-size;
  letter-spacing: 0.68px;
}
