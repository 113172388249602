@import "../../base.module.scss";

.sessionContainer {
  float: left;
  width: 33.333333%;
  display: block;
  padding: 23px;
}

.session {
  padding: 17px 16px 15px 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
}

.basicInfoRow {
  @include clearfix;
  margin-bottom: 17px;
}

.nameContainer {
  float: left;
}

.baseId {
  @include font-semibold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
}

.name {
  display: block;
  // This should be 2 * lineHeight (which is specified in js)
  height: 52px;
}

.activityRow {
  @include clearfix;
  margin-bottom: 20px;
}

.activityRowLeft {
  overflow: hidden;
  padding-right: 21px;
}

.activityRowRight {
  float: right;
  margin-top: 10px;
}

.resumeText {
  @include font-medium;
  color: $polyupaths-orange-color;
  font-size: 20px;
  line-height: 27px;
  padding-left: 25px;
  background: url("../../images/session_resume.svg") center left / 19px auto
    no-repeat;
}

.activityName {
  color: $polyupaths-orange-color;
  padding-bottom: 6px;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.progressBar {
  height: 5px;
}

.teacherName {
  margin-right: 10px;
}

.datetimeLabel {
  font-size: 12px;
  line-height: 17px;
  color: $polyupaths-gray-color4;
  margin-right: 5px;
}

.datetime {
  font-size: 12px;
  line-height: 17px;
  color: $polyupaths-gray-color4;
}

.lowerRowContainer {
  @include clearfix;
}

.teacherNameAndDatetimeContainer {
  overflow: hidden;
}

.participantCountContainer {
  float: right;
}

.participantCountText {
  @include font-light;
  font-size: 32px;
  padding-left: 30px;
  background: url("../../images/participants.svg") center left / 26px auto
    no-repeat;
}
