@import "../../base.module.scss";

.dashboardContainer {
  @include row;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
  position: relative;
}

.schoolAccountManagement {
  background-color: $polyupaths-red-color;
}

.schoolAccountManagementBottomBar {
  background-color: darken($polyupaths-red-color, 5%);
}

.templateManagement {
  background-color: $polyupaths-orange-color;
}

.templateManagementBottomBar {
  background-color: darken($polyupaths-orange-color, 5%);
}

.recordsManagement {
  background-color: $polyupaths-green-color;
}

.recordsManagementBottomBar {
  background-color: darken($polyupaths-green-color, 5%);
}

.adminUserManagement {
  background-color: $polyupaths-green-color2;
}

.adminUserManagementBottomBar {
  background-color: darken($polyupaths-green-color2, 5%);
}

.auditTrail {
  background-color: $polyupaths-blue-color;
}

.auditTrailBottomBar {
  background-color: darken($polyupaths-blue-color, 5%);
}

.systemConfiguration {
  background-color: $polyupaths-blue-color2;
}

.systemConfigurationBottomBar {
  background-color: darken($polyupaths-blue-color2, 5%);
}
