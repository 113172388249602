@import "../../base.module.scss";

.headerContainer {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 25px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid $polyupaths-gray-color;
  position: relative;
}

.breadcrumbsContainer {
  float: left;
  // 550 is buttonsContainer size
  margin-right: 550px;
}

.arrowIcon {
  float: left;
  margin-top: 7px;
}
.breadcrumbsText {
  @include font-medium;
  font-size: $large-body-font-size;
  padding: 6px 10px;
  line-height: 23px;
  float: left;
}

.buttonsContainer {
  position: absolute;
  top: 25px;
  right: 30px;
}

.createTemplateButton {
  float: right;
  margin: 0;
}

.createSessionButton {
  float: right;
  margin-left: 7px;
  margin-right: 7px;
}

.editTemplateButton {
  float: right;
  background-color: $polyupaths-green-color;
  border: none;
  @include hover-background-color-darken($polyupaths-green-color);
}

.activityRecordsButton {
  float: right;
  color: $polyupaths-black-color;
  // adjust padding because of border width is 1px
  padding: 7px 17px;
}

.disabledButton {
  opacity: 0.4;
  cursor: not-allowed;
}

.templateYear {
  color: $polyupaths-green-color;
}

.templateName {
  float: none;
  overflow: hidden;
}
