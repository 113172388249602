@import "../../base.module.scss";

.indicatorContainer {
  @include horizontal-vertically-center-child-element;
  width: 44px;
  height: 44px;
}

.small {
  width: 16px;
  height: 16px;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.indicatorContainer .spinner {
  width: 100%;
  height: 100%;
  display: block;
  background-image: url(../../images/loading.png);
  background-size: contain;
  animation: spinner 0.6s linear infinite;
}
