@import "../../base.module.scss";

.error {
  border: 2px solid $polyupaths-red-color;
}

.errorText {
  font-size: $smaller-body-font-size;
  color: $polyupaths-red-color;
  letter-spacing: 0.52px;
  margin-top: 5px;
}
