@import "../../base.module.scss";

.input {
  @include font-medium;
  font-size: $large-body-font-size;
  padding: 4px 5px;
  line-height: 23px;
  width: 100%;
  border: 1px solid $polyupaths-gray-color2;
}
