@import "../../base.module.scss";

.createSessionButtonContainer {
  float: left;
}

.previewIconButton {
  float: left;
  background-color: $polyupaths-orange-color;
  border: none;
  border-radius: 24.5px 0 0 24.5px;
  @include hover-background-color-darken($polyupaths-orange-color);
}

.startIconButton {
  float: left;
  background-color: $polyupaths-red-color;
  border: none;
  border-radius: 0 24.5px 24.5px 0;
  @include hover-background-color-darken($polyupaths-red-color);
}
