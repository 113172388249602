@import "../../base.module.scss";

.selectContainer {
  position: relative;
  border-radius: 9999px;
  border: 1px solid $polyupaths-gray-color2;
  padding: 0 24px 0 24px;
  &::after {
    content: "";
    @include make-triangle(bottom, 10px, 6px, #333333);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.select {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  opacity: 0;
  &[disabled] {
    cursor: not-allowed;
  }
}
