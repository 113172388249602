@import "../../base.module.scss";

.iconButton {
  color: white;
  padding: 8px 18px;
  border: 1px solid $polyupaths-gray-color2;
  border-radius: 24.5px;
  height: 36px;
  cursor: pointer;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.icon {
  display: inline-block;
  min-width: 18px;
}

.iconButton img {
  vertical-align: middle;
}

.iconButton p {
  @include font-semibold;
  display: inline-block;
  font-size: $smaller-body-font-size;
  padding-left: 6px;
  letter-spacing: 0.52px;
}
