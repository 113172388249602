@import "../../base.module.scss";

.footer {
  background-color: #666666;
  height: $dashboard-footer-height;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-fixed;
}

.link {
  @include column(1);
  line-height: $dashboard-footer-height;
  color: white;
  font-size: $smaller-body-font-size;
  cursor: pointer;
}

.localeSwitchContainer {
  float: right;
  margin-top: 7.5px;
}
