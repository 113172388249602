@import "../../base.module.scss";

.buttonText {
  @include font-semibold;
  font-size: 16px;
  &.submit {
    line-height: 44px;
  }
  &.submitted {
    line-height: 40px;
    padding-left: 36px;
    background: url("../../images/student_session_submitted.svg") left center /
      22px 17px no-repeat;
  }
}
