@import "../../base.module.scss";

$cht-name-line-height: 22px;
$cht-name-margin-bottom: 1px;
$eng-name-line-height: 20px;
$row-line-height: $cht-name-line-height + $cht-name-margin-bottom +
  $eng-name-line-height;
$edit-button-height: 36px;

.templateContainer {
  @include clearfix;
  padding: 16px $admin-dashboard-horizontal-padding;
  border-bottom: 1px solid $polyupaths-gray-color2;
  position: relative;
}

.displayCode {
  float: left;
  width: 10%;
  padding-right: 10px;
  overflow: hidden;
  line-height: $row-line-height;
  font-size: $smaller-body-font-size;
  @include font-medium;
  @include single-line-truncate;
}

.nameContainer {
  float: left;
  width: 50%;
  padding-right: 10px;
}

.name {
  line-height: $cht-name-line-height;
  font-size: 16px;
  margin-bottom: $cht-name-margin-bottom;
  @include font-medium;
  @include single-line-truncate;
}

.info {
  line-height: $eng-name-line-height;
  font-size: $smaller-body-font-size;
  color: $polyupaths-gray-color4;
  @include single-line-truncate;
}

.createdAt {
  float: left;
  width: 12.5%;
  overflow: hidden;
  line-height: $row-line-height;
  font-size: 12px;
  @include font-medium;
}

.editButtonContainer {
  float: right;
  width: 125px;
  height: $edit-button-height;
  margin-top: ($row-line-height - $edit-button-height) / 2;
  margin-right: 10px;
}

.previewButtonContainer {
  float: right;
  width: 120px;
  height: $edit-button-height;
  margin-top: ($row-line-height - $edit-button-height) / 2;
  margin-right: 30px;
}

.editButton {
  background-color: $polyupaths-green-color;
  border: none;
  @include hover-background-color-darken($polyupaths-green-color);
}

.previewButton {
  background-color: $polyupaths-orange-color;
  border: none;
  @include hover-background-color-darken($polyupaths-orange-color);
}

.moreButton {
  position: absolute;
  padding: 5px 9px 6px 9px;
  top: 19px;
  right: 31px;
}

.moreButtonIcon {
  position: relative;
  top: 3px;
}

.moreActionsPopupMenu {
  position: absolute;
  right: 45px;
  top: 15px;
  z-index: $zIndex-popup;
  background-color: #ffffff;
  border: 1px solid $polyupaths-gray-color;
}

.popupMenuItem {
  color: $polyupaths-black-color;
  border-radius: 0;
  border: 0;
  padding: 15px 17px;
  height: auto;
}
