@import "./variables.module.scss";

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  position: relative;
}

body {
  // en, macOS/iOS, Windows, Android, Linux, generic
  font-family: $font-list;
  color: $polyupaths-black-color;
}

input {
  border: 0;
  outline: none;
}

button {
  border: 0;
  outline: none;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: normal;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  width: auto;
  &[disabled] {
    cursor: not-allowed;
  }
}

input[type="file"] {
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}
