@import "../../base.module.scss";

$red-color: #f0453a;
$green-color: $polyupaths-green-color;
$blue-color: #4b6add;
$pink-color: #fb4c69;
$orange-color: #f8ab45;

.alertContainer {
  @include font-semibold;
  display: block;
  background-color: white;

  @include layout-desktop {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px 74px 60px 74px;
    width: 779px;
    margin: 0 auto;
  }

  @include layout-mobile {
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 62px 18px 68px 18px;
  }
}

.title {
  display: block;
  font-size: 26px;
}

.message {
  color: black;
  display: block;
  font-size: 16px;
  margin: 19px 0 36px 0;
}

.buttonsContainer {
  @include clearfix;
}

.buttonsDefaultMarginTop {
  margin-top: 16px;
}

.button {
  @include font-medium;
  font-size: 16px;
  padding: 10px 52px 12px 52px;
}

.cancelButton {
  @include font-medium;
  font-size: 16px;
  padding: 10px 52px 12px 52px;
  margin-left: 16px;
}

.closeButton {
  display: block;
  background-color: white;
  position: absolute;
  width: 56px;
  height: 56px;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
}

.closeIcon {
  width: 16px;
  height: 17px;
}

.red .title {
  color: $red-color;
}

.green .title {
  color: $green-color;
}

.blue .title {
  color: $blue-color;
}

.pink .title {
  color: $pink-color;
}

.orange .title {
  color: $orange-color;
}
