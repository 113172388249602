@import "./variables.module.scss";
@import "./grid.module.scss";

@mixin font-light {
  font-weight: $font-weight-light;
}

@mixin font-regular {
  font-weight: $font-weight-regular;
}

@mixin font-semibold {
  font-weight: $font-weight-semibold;
}

@mixin font-medium {
  font-weight: $font-weight-medium;
}

@mixin hover-background-color-darken($backgroundColor, $amount:5%) {
  &:hover {
    background-color: darken($backgroundColor, $amount);
  }
}

@mixin make-triangle($direction, $width, $height, $color) {
  width: 0;
  height: 0;
  border-style: solid;
  @if $direction == top {
    border-width: 0 ($width / 2) $height ($width / 2);
    border-color: transparent transparent $color transparent;
  }
  @if $direction == bottom {
    border-width: $height ($width / 2) 0 ($width / 2);
    border-color: $color transparent transparent transparent;
  }
  @if $direction == left {
    border-width: ($height / 2) $width ($height / 2) 0;
    border-color: transparent $color transparent transparent;
  }
  @if $direction == right {
    border-width: ($height / 2) 0 ($height / 2) $width;
    border-color: transparent transparent transparent $color;
  }
}

@mixin vertically-center-child-element {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-vertically-center-child-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin layout-desktop {
  @media (min-width: $content-min-width + 1px) {
    @content;
  }
}

@mixin layout-mobile {
  @media (max-width: $content-min-width) {
    @content;
  }
}

@mixin single-line-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container {
  @include content-container;
}

.row {
  @include row;
}
