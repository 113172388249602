$font-list: Arial, "PingFang HK", "Microsoft JhengHei", "Noto Sans CJK TC",
  "Source Han Sans TC", sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 500;
$body-font-size: 16px;
$smallest-body-font-size: 10px;
$small-body-font-size: 12px;
$smaller-body-font-size: 14px;
$large-body-font-size: 18px;
$larger-body-font-size: 24px;
$header-margin-bottom: 19px;
$polyupaths-orange-color: #f8ab45;
$polyupaths-red-color: #f0453a;
$polyupaths-red-color2: #fb4c69;
$polyupaths-blue-color: #4b6add;
$polyupaths-blue-color2: #4a44ab;
$polyupaths-black-color: #333333;
$polyupaths-gray-color: #e6e6e6;
$polyupaths-gray-color2: #cdcdcd;
$polyupaths-gray-color3: #f9f9f9;
$polyupaths-gray-color4: #8e8e8e;
$polyupaths-gray-color5: #666666;
$polyupaths-green-color: #008588;
$polyupaths-green-color2: #00a5a8;
$zIndex-popup: 1030;
$zIndex-modal: 1040;
$zIndex-fixed: 1050;
$dashboard-footer-height: 44px;
$admin-dashboard-footer-height: 77px;
$admin-dashboard-horizontal-padding: 40px;

/* stylelint-disable */
:export {
  // Caveat: prettier will format this property value lowercase
  // e.g.
  // rebeccaPurple -> rebeccapurple
  fontlist: $font-list;
  orange: $polyupaths-orange-color;
  red: $polyupaths-red-color;
  red2: $polyupaths-red-color2;
  blue: $polyupaths-blue-color;
  gray1: $polyupaths-gray-color;
  gray2: $polyupaths-gray-color2;
  gray3: $polyupaths-gray-color3;
  gray4: $polyupaths-gray-color4;
  gray5: $polyupaths-gray-color5;
  green: $polyupaths-green-color;
  green2: $polyupaths-green-color2;
}
/* stylelint-enable */
