@import "../../base.module.scss";

.progressBarContainer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: $polyupaths-gray-color;
}

.progress {
  position: absolute;
  z-index: 2;
  border-radius: 10px;
  left: 0;
  top: 0;
  bottom: 0;
}
