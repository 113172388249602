@import "../../base.module.scss";

.imageContainer {
  position: relative;
  margin-top: 17px;
}

.closeButton {
  position: absolute;
  top: -17px;
  right: -17px;
  cursor: pointer;
}
