@import "../../base.module.scss";

.activityActionsContainer {
  @include clearfix;
}

.removeActivity {
  float: right;
  height: initial;
  margin-bottom: 30px;
  border: none;
  padding: 0;
  color: $polyupaths-red-color;
}

.previewActivity {
  float: right;
  height: initial;
  margin-right: 30px;
  margin-bottom: 30px;
  border: none;
  padding: 0;
  color: $polyupaths-orange-color;
}
