@import "../../base.module.scss";

.modalContainer {
  display: block;
  background-color: white;
  padding: 50px 75px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 779px;
  margin: 0 auto;
}

.title {
  font-size: 26px;
  line-height: 37px;
  font-weight: $font-weight-medium;
  margin-bottom: 30px;
}

.addContainer .title {
  color: $polyupaths-blue-color;
}

.editContainer .title {
  color: $polyupaths-green-color;
}

.labelRow {
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 22px;
  font-weight: $font-weight-medium;
  margin-bottom: 18px;
}

.inputRow {
  @include clearfix;
  margin-bottom: 20px;
}

.input {
  display: block;
  font-weight: $font-weight-regular;
  padding: 9px 15px;
  line-height: 25px;
}

.inputCol {
  width: 390px;
  float: left;
  display: block;
  font-weight: $font-weight-regular;
}

.showPasswordRow {
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 22px;
  font-weight: $font-weight-medium;
  margin-bottom: 20px;

  input[type="checkbox"] {
    margin-right: 10px;
  }
}

.buttonRow {
  @include clearfix;
}

.saveButton {
  padding: 10px 65px;
  float: right;
}

.closeButton {
  display: block;
  background-color: white;
  position: absolute;
  width: 56px;
  height: 56px;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
}

.closeIcon {
  width: 16px;
  height: 17px;
}

.userTypeSelect {
  float: right;
  font-size: 16px;
  min-width: 215px;
}

.userTypeSelectedOption {
  font-size: 16px;
  line-height: 45px;
}
