@import "../../base.module.scss";

.rootContainer {
  border-top: 8px solid $polyupaths-orange-color;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
  background-color: $polyupaths-gray-color3;
}

.mainContentContainer {
  @include clearfix;
  padding-top: 28px;
}

.leftPanelContainer {
  float: left;
  @include column-width(3);
  padding: 0 20px;
  border-right: 1px solid $polyupaths-gray-color;
}

.rightPanelContainer {
  float: left;
  padding-right: 34px;
  @include column-width(9);
}

.activitiesTitleText {
  @include font-medium;
  font-size: 16px;
  color: $polyupaths-black-color;
  padding: 30px 0 18px 0;
}

.labelContainer {
  position: relative;
  border-radius: 9999px;
  border: 1px solid $polyupaths-gray-color2;
  padding: 0 24px 0 24px;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.labelText {
  line-height: 45px;
  min-height: 45px;
  @include single-line-truncate;
}
