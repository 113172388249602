@import "../../base.module.scss";

.headerContainer {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 25px 30px;
  background-color: #ffffff;
  border-bottom: 1px solid $polyupaths-gray-color;
}

.nameInputContainer {
  float: left;
  width: 50%;
}

.nameInputText {
  @include font-medium;
  font-size: $large-body-font-size;
  padding: 6px 7px 6px 0;
  line-height: 23px;
  float: left;
}

.inputContainer {
  width: 70%;
  float: left;
  margin-left: 20px;
}

.buttonsContainer {
  float: right;
  width: 50%;
}

.cancelButton {
  float: right;
  color: $polyupaths-black-color;
  border: 1px solid #cdcdcd;
  padding: 6px 25px;
  line-height: 22px;
  font-size: $smaller-body-font-size;
  @include font-regular;
}

.finishEditTemplateButton {
  float: right;
  background-color: $polyupaths-green-color;
  border: none;
  color: #ffffff;
  margin-left: 15px;
  @include hover-background-color-darken($polyupaths-green-color);

  p {
    font-size: $smaller-body-font-size;
    @include font-regular;
  }
}
