@import "../../base.module.scss";

.container {
  @include clearfix;
  padding: 7px $admin-dashboard-horizontal-padding;
  border-bottom: 1px solid $polyupaths-gray-color2;
  position: relative;
}

.title {
  float: left;
  color: $polyupaths-gray-color5;
  text-transform: uppercase;
  line-height: 17px;
  letter-spacing: 0.75px;
  font-size: $small-body-font-size;
  @include font-medium;
}
