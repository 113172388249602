@import "../../../base.module.scss";

.recordsContainer {
  @include row;
  background-color: $polyupaths-gray-color3;
  padding-bottom: $dashboard-footer-height;
  border-top: 8px solid $polyupaths-red-color;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
  position: relative;
}

.activitiesContainer {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 28px 30px 0;
}

.activitiesPanel {
  @include column(3);
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid $polyupaths-gray-color;
}

.activityResultPanel {
  @include column(9);
  padding-left: 0;
  padding-right: 0;
  left: -1px;
  border-left: 1px solid $polyupaths-gray-color;
}

.activitiesTitle {
  @include font-semibold;
  letter-spacing: 0.6px;
  margin-bottom: 18px;
}

.selectorContainer {
  margin: 0 20px 30px 0;
  position: relative;

  &::after {
    content: "";
    @include make-triangle(bottom, 10px, 6px, #333333);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.selectOptionText {
  line-height: 35px;
  @include single-line-truncate;
}

.recordSelector {
  padding: 9px 24px;
  border: 1px solid $polyupaths-gray-color2;
  height: 55px;
  width: 100%;
  border-radius: 27.5px;
  background-color: #ffffff;
  @include font-medium;
  font-size: $smaller-body-font-size;
  line-height: 20px;

  /* reset */
  appearance: none;
}

.noActivityRecordsText {
  @include horizontal-vertically-center-child-element;
  color: $polyupaths-gray-color4;
  font-size: $large-body-font-size;
  letter-spacing: 0.68px;
}
