@import "../../base.module.scss";

$whiteboard-button-width: 280px;
$whiteboard-button-margin: 10px;

.activityNameText {
  font-size: 26px;
  color: $polyupaths-green-color;
  text-align: center;
  @include font-semibold;
  @include layout-desktop {
    padding: 20px 0 10px 0;
    margin: 0 auto;
    width: 66.666666%;
    @include single-line-truncate;
  }
  @include layout-mobile {
    padding: 20px 20px 10px 20px;
  }
}

.questionContainer {
  @include clearfix;
  @include layout-desktop {
    width: 66.666666%;
    margin: 0 auto;
  }
}

.questionImage {
  display: block;
  @include layout-desktop {
    float: left;
  }
  @include layout-mobile {
    margin: 0 auto;
  }
}

.questionText {
  font-size: 18px;
  @include layout-desktop {
    overflow: hidden;
    padding-bottom: 20px;
  }
  @include layout-mobile {
    padding: 10px 20px 0 20px;
  }
}

.questionTextWithImage {
  font-size: 18px;
  @include layout-desktop {
    overflow: hidden;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  @include layout-mobile {
    padding: 10px 20px 0 20px;
  }
}

.qaTextAreaContainer {
  @include layout-desktop {
    @include column-width(8);
    margin: 20px auto 0 auto;
    height: 125px;
  }
  @include layout-mobile {
    margin: 20px 20px 0 20px;
    height: 184px;
  }
}

.qaTextArea {
  /* stylelint-disable */
  resize: none;
  /* stylelint-enable */
  display: block;
  font-size: 16px;
  padding: 10px 16px;
  border: 1px solid $polyupaths-gray-color2;
  width: 100%;
  height: 100%;
}

.submitButtonContainer {
  display: block;
  position: relative;
  @include layout-desktop {
    padding: 30px 0;
  }
  @include layout-mobile {
    padding: 30px 20px;
  }
}

.submitButton {
  @include layout-desktop {
    position: relative;
    display: block;
    height: 44px;
    width: 280px;
    left: 50%;
    transform: translate(-50%);
  }
  @include layout-mobile {
    display: block;
    width: 100%;
    height: 44px;
  }
}

.pollingOptionList {
  @include clearfix;
  display: block;
  list-style-type: none;
  @include layout-desktop {
    @include column-width(8);
    margin: 0 auto;
    padding: 15px 0 0 0;
  }
  @include layout-mobile {
    padding: 15px 15px 0 15px;
  }
}

.pollingOptionItem {
  display: block;
  padding: 5px;
}

.pollingOptionItemButton {
  @include clearfix;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 34px;

  &.notSelected {
    padding: 8px 24px;
  }
  &.selected {
    padding: 9px 25px;
  }
}

.pollingOptionAlpha {
  float: left;
  font-size: 16px;
  padding: 0 16px 0 0;
  line-height: 22px;

  &.notSelected {
    color: $polyupaths-green-color;
  }
  &.selected {
    @include font-semibold;
    color: white;
  }
}

.pollingOptionContentContainer {
  display: block;
  overflow: hidden;
}

.pollingOptionText {
  text-align: initial;
  font-size: 16px;
  line-height: 22px;

  &.notSelected {
    color: $polyupaths-black-color;
  }
  &.selected {
    @include font-semibold;
  }
}

.pollingOptionImage {
  float: left;
  margin: 0 10px 10px 0;
}

.whiteboardDrawingBoardContainer {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  @include layout-desktop {
    width: 83.333333%;
    height: 545px;
    margin: 0 auto;
  }
  @include layout-mobile {
    width: 100%;
  }
}

.whiteboardButtonContainer {
  @include layout-desktop {
    @include clearfix;
    padding: 30px 0;
    width: 2 * $whiteboard-button-width + 4 * $whiteboard-button-margin;
    margin: 0 auto;
  }
  @include layout-mobile {
    padding: 30px 20px;
  }
}

@mixin whiteboardButton {
  @include font-medium;
  font-size: 16px;
  @include layout-desktop {
    float: left;
    width: $whiteboard-button-width;
    height: 44px;
    margin: 0 $whiteboard-button-margin;
  }
  @include layout-mobile {
    display: block;
    width: 100%;
    line-height: 44px;
    margin-bottom: 10px;
  }
}

.whiteboardExportButton {
  @include whiteboardButton;
}

.whiteboardSubmitButton {
  @include whiteboardButton;
}
