@import "../../base.module.scss";

.styledDatePicker {
  position: relative;
  border: 1px solid $polyupaths-gray-color2;
  padding: 0 24px;
  border-radius: 9999px;
  &::after {
    content: "";
    @include make-triangle(bottom, 11px, 6px, $polyupaths-black-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
  }
}

.labelText {
  font-size: 16px;
  line-height: 34px;
  margin-right: 7px;
}

.dateText {
  @include font-medium;
  font-size: 16px;
  line-height: 34px;
}
