@import "../../base.module.scss";

.editTitle {
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6px;
  margin-bottom: 9px;
}

.hintText {
  @include font-medium;
  color: $polyupaths-gray-color2;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.45px;
  padding-top: 10px;
}

.stickerImgs {
  @include clearfix;
  display: block;
  margin-bottom: 19px;
}

.stickerImg {
  float: left;
  margin-right: 20px;
}

.backgroundImage {
  margin-top: 9px;
  margin-bottom: 9px;
}

.radioButtonsContainer {
  @include clearfix;
  margin-bottom: 30px;
}

.radioButton {
  float: left;
  position: relative;
  min-height: 22px;
  margin-right: 28px;
}

.radioButtonInput {
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 16px;
  height: 16px;
}

.radioButtonContent {
  margin-left: 37px;
}

.radioButtonLabel {
  color: $polyupaths-black-color;
  padding-bottom: 9px;
}

.selectContainer {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    @include make-triangle(bottom, 10px, 6px, $polyupaths-black-color);
  }
}

.select {
  background-color: white;
  border: 1px solid $polyupaths-gray-color2;
  border-radius: 9999px;
  padding: 7px 40px 7px 17px;
  appearance: none;
  font-size: 14px;
  color: $polyupaths-black-color;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.errorText {
  color: $polyupaths-red-color;
  font-size: 14px;
}

.selectOptionText {
  @include single-line-truncate;
}
