@import "../../base.module.scss";

$toolbar-row-height-mobile: 64px;
$toolbar-row-height-desktop: 98px;

@mixin bottomSeparator {
  border-bottom: 1px solid $polyupaths-green-color;
}
@mixin leftSeparator {
  border-left: 1px solid $polyupaths-green-color;
}

.toolbox {
  position: relative;
  background-color: $polyupaths-green-color2;
  transition: margin-left 0.25s, margin-top 0.25s;

  @include layout-desktop {
    float: left;
    height: 100%;
    min-height: 545px;
    &.collapsed {
      margin-left: -200px;
    }
  }
  @include layout-mobile {
    @include clearfix;
    &.collapsed {
      margin-top: -2 * $toolbar-row-height-mobile;
    }
  }
}

@mixin pencilPointerButton {
  float: left;
  position: relative;
  @include layout-desktop {
    width: 89px;
    height: 66px;
  }
  @include layout-mobile {
    width: 52.5px;
    height: 48px;
  }
  &.selected {
    background-color: white;
  }
  &.unselected {
    background-color: $polyupaths-green-color;
  }
}

@mixin pencilPointerButtonText($position) {
  @include font-medium;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @if $position == left {
    @include layout-mobile {
      right: 10px;
    }
    @include layout-desktop {
      right: 24px;
    }
  }
  @if $position == right {
    @include layout-mobile {
      left: 10px;
    }
    @include layout-desktop {
      left: 24px;
    }
  }

  &.selected {
    color: $polyupaths-green-color2;
  }
  &.unselected {
    color: white;
  }

  @include layout-mobile {
    padding-top: 20px;
    font-size: $small-body-font-size;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto 20px;
  }

  @include layout-desktop {
    padding-top: 28px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto 28px;
  }
}

.pencilButton {
  @include pencilPointerButton;
}

.pencilButtonText {
  @include pencilPointerButtonText(right);
  &.selected {
    background-image: url("../../images/drawing_pencil_selected.svg");
  }
  &.unselected {
    background-image: url("../../images/drawing_pencil_unselected.svg");
  }
}

.pointerButton {
  @include pencilPointerButton;
}

.pointerButtonText {
  @include pencilPointerButtonText(left);
  &.selected {
    background-image: url("../../images/drawing_pointer_selected.svg");
  }
  &.unselected {
    background-image: url("../../images/drawing_pointer_unselected.svg");
  }
}

.pencilEraserButton {
  overflow: hidden;
  border-radius: 9999px;
  @include clearfix;
  @include layout-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 105px;
    transform: translateX(-50%) translateY(-50%);
  }
}

.strokeWidthButtonContainer {
  display: block;
  text-align: center;
  float: left;
  width: 50%;
  @include layout-desktop {
    padding: 10px 10px;
  }
  @include layout-mobile {
    float: left;
    width: 60%;
    padding: 15px 0;
  }
}

.strokeWidthButtonGroup {
  @include clearfix;
  @include layout-desktop {
    display: block;
    height: 40px;
  }
  @include layout-mobile {
    height: 20px;
  }
}

.strokeWidthButtonWrapper {
  display: block;
  position: relative;
  float: left;
  width: 33%;
  height: 100%;
}

.strokeWidthButtonOuter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 9999px;
  &.selected {
    border: 1px solid $polyupaths-gray-color;
  }
  @include layout-desktop {
    &.w1 {
      width: 16px;
      height: 16px;
    }
    &.w2 {
      width: 20px;
      height: 20px;
    }
    &.w3 {
      width: 24px;
      height: 24px;
    }
    &.w4 {
      width: 30px;
      height: 30px;
    }
  }
  @include layout-mobile {
    &.w1 {
      width: 14px;
      height: 14px;
    }
    &.w2 {
      width: 16px;
      height: 16px;
    }
    &.w3 {
      width: 20px;
      height: 20px;
    }
    &.w4 {
      width: 24px;
      height: 24px;
    }
  }
}
.strokeWidthButtonInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 9999px;
  @include layout-desktop {
    &.w1 {
      width: 10px;
      height: 10px;
    }
    &.w2 {
      width: 14px;
      height: 14px;
    }
    &.w3 {
      width: 18px;
      height: 18px;
    }
    &.w4 {
      width: 24px;
      height: 24px;
    }
  }
  @include layout-mobile {
    &.w1 {
      width: 8px;
      height: 8px;
    }
    &.w2 {
      width: 10px;
      height: 10px;
    }
    &.w3 {
      width: 14px;
      height: 14px;
    }
    &.w4 {
      width: 18px;
      height: 18px;
    }
  }
}

@mixin addThingButton {
  position: relative;
  &.selected {
    background-color: white;
  }
  &.active {
    background-color: white;
  }
}

@mixin addThingButtonText {
  display: block;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  color: white;
  text-align: center;
  line-height: 32px;
  &.active {
    color: $polyupaths-green-color;
    background-color: white;
  }
  &.selected {
    color: $polyupaths-green-color;
    background-color: white;
  }
  @include layout-desktop {
    padding: 60px 20px 6px 20px;
    background-position: center 20px;
    background-size: auto 38px;
  }
  @include layout-mobile {
    padding: 24px 10px 0 10px;
    background-position: center 8px;
    background-size: auto 24px;
  }
}

@mixin other-button-desktop {
  float: left;
  width: 50%;
  height: $toolbar-row-height-desktop;
  font-size: 14px;
}

@mixin other-button-mobile {
  float: left;
  width: 50%;
  height: $toolbar-row-height-mobile;
  font-size: 12px;
}

@mixin wrappedButtonContainer {
  position: relative;
  @include layout-desktop {
    @include other-button-desktop;
  }
  @include layout-mobile {
    @include other-button-mobile;
  }
}

@mixin wrappedButton {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.addTextButton {
  @include addThingButton;
  @include layout-desktop {
    @include other-button-desktop;
  }
  @include layout-mobile {
    @include other-button-mobile;
  }
}

.addTextButtonText {
  @include addThingButtonText;
  background-image: url("../../images/drawing_addtext_inactive.svg");
  &.selected {
    background-image: url("../../images/drawing_addtext_active.svg");
  }
}

.addShapeButtonContainer {
  @include wrappedButtonContainer;
}

.addShapeButton {
  @include addThingButton;
  @include wrappedButton;
}

.addShapeButtonText {
  @include addThingButtonText;
  background-image: url("../../images/drawing_addshape_inactive.svg");
  &.active {
    background-image: url("../../images/drawing_addshape_active.svg");
  }
}

.shapeSelectionPanel {
  @include clearfix;
  position: absolute;
  z-index: 1;
  background-color: $polyupaths-green-color;
  height: 184px;
  width: 218px;
  padding: 9px 9px 21px 9px;
  @include layout-desktop {
    top: 0;
    right: 0;
    transform: translateX(100%);
  }
  @include layout-mobile {
    top: 100%;
  }
}

.shapeSelectionPanelTitleText {
  color: white;
  font-size: 14px;
  line-height: 20px;
  padding: 1px 11px 6px 11px;
}

.shapeButtonWrapper {
  float: left;
  padding: 5px;
}

.shapeButton {
  display: block;
  width: 54px;
  height: 54px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;

  &.circle {
    background-image: url("../../images/drawing_shape_circle.svg");
  }
  &.rectangle {
    background-image: url("../../images/drawing_shape_rectangle.svg");
  }
  &.heart {
    background-image: url("../../images/drawing_shape_heart.svg");
  }
  &.star {
    background-image: url("../../images/drawing_shape_star.svg");
  }
  &.triangle {
    background-image: url("../../images/drawing_shape_triangle.svg");
  }
  &.pentagon {
    background-image: url("../../images/drawing_shape_pentagon.svg");
  }
}

.addStickerButtonContainer {
  @include wrappedButtonContainer;
}

.addStickerButton {
  @include addThingButton;
  @include wrappedButton;
}

.addStickerButtonText {
  @include addThingButtonText;
  background-image: url("../../images/drawing_addsticker_inactive.svg");
  &.active {
    background-image: url("../../images/drawing_addsticker_active.svg");
  }
}

.stickerSelectionPanel {
  @include clearfix;
  position: absolute;
  z-index: 1;
  background-color: $polyupaths-green-color;
  @include layout-desktop {
    bottom: 0;
    left: 100%;
  }
  @include layout-mobile {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.stickerSelectionPanelTitleText {
  color: white;
  font-size: 14px;
  line-height: 40px;
  padding: 0 20px;
}

.stickerSelectionPanelScrollView {
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 8px 0 15px;
  margin: 0 20px 20px 20px;
  height: 252px;
  background-color: #046a6c;

  &::-webkit-scrollbar {
    appearance: none;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.4);
  }

  @include layout-desktop {
    min-width: 312px;
  }
  @include layout-mobile {
    min-width: 218px;
  }
}

.stickerButton {
  display: block;
  float: left;
  padding: 3px;
}

.addImageButton {
  display: block;
  position: relative;
  @include addThingButton;
  @include layout-desktop {
    @include other-button-desktop;
  }
  @include layout-mobile {
    @include other-button-mobile;
  }
}

.addImageButtonText {
  @include addThingButtonText;
  background-image: url("../../images/drawing_addimage.svg");
}

.addImageButtonInput {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.pickColorButtonContainer {
  display: block;
  text-align: center;
  float: left;
  width: 50%;
  cursor: pointer;
  position: relative;
  @include layout-desktop {
    height: $toolbar-row-height-desktop;
  }
  @include layout-mobile {
    height: $toolbar-row-height-mobile;
  }
}

.pickColorButtonContent {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.pickPencilColorButtonContainer {
  position: relative;
  @include layout-mobile {
    padding: 15px 0 15px 0;
    width: 40%;
  }
}

.pickColorButtonContainerDisabled {
  cursor: not-allowed;
  // should use pointer-events: none to disable mouse action
  // but at the same time, cursor couldn't stay in not-allowed style
}

@mixin make-pick-color-button-triangle {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 9px;
  border-color: transparent transparent $polyupaths-black-color transparent;
}

@mixin make-pick-color-button-transparent-indicator-desktop {
  content: "";
  display: block;
  width: 53px;
  height: 4px;
  position: absolute;
  background-color: $polyupaths-red-color;
  transform: rotate(-45deg);
}

@mixin make-pick-color-button-transparent-indicator-mobile {
  content: "";
  display: block;
  width: 40px;
  height: 4px;
  position: absolute;
  background-color: $polyupaths-red-color;
  transform: rotate(-45deg);
}

.pickColorButton {
  display: block;
  position: relative;
  margin: 0 auto;
  &.stroke {
    background-color: transparent;
  }
  @include layout-desktop {
    width: 40px;
    height: 40px;
    &.fill {
      border: 2px solid $polyupaths-gray-color;
    }
    &.fill::before {
      @include make-pick-color-button-triangle;
      right: -2px;
      bottom: -2px;
    }
    &.pencil {
      border: 2px solid $polyupaths-gray-color;
    }
    &.pencil::before {
      @include make-pick-color-button-triangle;
      right: -2px;
      bottom: -2px;
    }
    &.stroke::before {
      @include make-pick-color-button-triangle;
      right: -5px;
      bottom: -5px;
    }
    &.stroke.transparent::after {
      @include make-pick-color-button-transparent-indicator-desktop;
      top: 13px;
      left: -12px;
    }
    &.fill.transparent::after {
      @include make-pick-color-button-transparent-indicator-desktop;
      top: 16px;
      left: -9px;
    }
  }
  @include layout-mobile {
    width: 20px;
    height: 20px;
    &.fill {
      border: 1px solid $polyupaths-gray-color;
    }
    &.fill::before {
      @include make-pick-color-button-triangle;
      right: -1px;
      bottom: -1px;
    }
    &.pencil {
      border: 1px solid $polyupaths-gray-color;
    }
    &.pencil::before {
      @include make-pick-color-button-triangle;
      right: -1px;
      bottom: -1px;
    }
    &.stroke::before {
      @include make-pick-color-button-triangle;
      right: -5px;
      bottom: -5px;
    }
    &.stroke.transparent::after {
      @include make-pick-color-button-transparent-indicator-mobile;
      top: 8px;
      left: -10px;
    }
    &.fill.transparent::after {
      @include make-pick-color-button-transparent-indicator-mobile;
      top: 12px;
      left: -6px;
    }
  }
}

.pickColorButtonText {
  display: block;
  @include font-medium;
  color: white;
  @include layout-mobile {
    padding-top: 2px;
    font-size: 12px;
  }
  @include layout-desktop {
    padding-top: 7px;
    font-size: 14px;
  }
}

.clearButtonOrDeleteButton {
  @include font-medium;
  float: left;
  display: block;
  position: relative;
  width: 50%;
  height: 100%;
  color: white;
  &[disabled] {
    color: rgba(255, 255, 255, 0.4);
  }
}

.clearButtonOrDeleteButtonText {
  display: block;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  @include layout-desktop {
    font-size: 19px;
  }
  @include layout-mobile {
    font-size: 14px;
  }
}

.collapseButton {
  display: block;
  background-color: $polyupaths-green-color;
  position: absolute;
  z-index: 1;
  @include layout-desktop {
    top: 0;
    right: -24px;
    width: 24px;
    height: 52px;
    border-radius: 0 5px 5px 0;
  }
  @include layout-mobile {
    left: 0;
    bottom: -22px;
    width: 71px;
    height: 22px;
    border-radius: 0 0 5px 5px;
  }
}

.collapseButtonArrow {
  position: absolute;
  top: 50%;
  left: 50%;
  @include layout-desktop {
    transform: translateX(-50%) translateY(-50%);
    &.collapsed {
      transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
  }
  @include layout-mobile {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    &.collapsed {
      transform: translateX(-50%) translateY(-50%) rotate(270deg);
    }
  }
}

.colorPalette {
  @include clearfix;
  position: absolute;
  width: 205px;
  background-color: $polyupaths-green-color;
  padding: 0 12.5px 12.5px 12.5px;
  z-index: 1;
  bottom: 0;
  right: 0;
  @include layout-desktop {
    transform: translateX(100%);
  }
  @include layout-mobile {
    transform: translateY(100%);
  }
}

.pencilColorPalette {
  top: 40px;
  bottom: auto;
  @include layout-mobile {
    top: auto;
    bottom: 0;
    left: 0;
  }
}

.colorPaletteTitleText {
  @include font-medium;
  color: white;
  font-size: 14px;
  padding: 10px 20px 4.5px 20px;
}

.colorPaletteItemContainer {
  float: left;
  padding: 7.5px;
  position: relative;
  &.selected {
    padding: 5px;
  }
  &.transparent::after {
    content: "";
    display: block;
    width: 42px;
    height: 4px;
    top: 20px;
    left: 1px;
    position: absolute;
    background-color: $polyupaths-red-color;
    transform: rotate(-45deg);
  }
  &.transparent.selected::after {
    content: "";
    display: block;
    width: 49.5px;
    height: 4px;
    top: 20px;
    left: -2px;
    position: absolute;
    background-color: $polyupaths-red-color;
    transform: rotate(-45deg);
  }
}

.colorPaletteItem {
  width: 30px;
  height: 30px;
  &.selected {
    width: 35px;
    height: 35px;
    border: 5px solid $polyupaths-gray-color;
  }
}

.toolGroup {
  position: relative;
  @include layout-desktop {
    display: block;
    padding: 18px 16px 17px 16px;
  }
  @include layout-mobile {
    float: left;
    height: $toolbar-row-height-mobile;
    width: 33%;
    text-align: center;
    @include bottomSeparator;
  }
}

.pencilGroup {
  @include clearfix;
  @include bottomSeparator;
  @include layout-mobile {
    float: left;
    width: 33%;
    height: $toolbar-row-height-mobile;
  }
}

.addStickerAndImageGroup {
  @include clearfix;
  @include layout-desktop {
    @include bottomSeparator;
  }
  @include layout-mobile {
    position: relative;
    float: left;
    width: 33%;
    height: $toolbar-row-height-mobile;
    @include leftSeparator;
    @include bottomSeparator;
  }
}

.addTextAndAddShapeGroup {
  @include clearfix;
  @include layout-mobile {
    float: left;
    width: 33%;
    height: $toolbar-row-height-mobile;
  }
}

.pickColorButtonGroup {
  @include clearfix;
  @include layout-desktop {
    @include bottomSeparator;
  }
  @include layout-mobile {
    position: relative;
    float: left;
    width: 33%;
  }
}

.clearButtonOrDeleteButtonGroup {
  @include layout-desktop {
    height: 49px;
  }
  @include layout-mobile {
    position: relative;
    float: left;
    width: 33%;
    height: $toolbar-row-height-mobile;
    @include leftSeparator;
  }
}
