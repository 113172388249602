@import "../../base.module.scss";

.activityContainer {
  @include clearfix;
  padding: 10px 15px;
  border: 1px solid $polyupaths-gray-color2;
  cursor: pointer;
  outline: none;
  position: relative;
  background-color: #ffffff;

  &.active {
    border: 2px solid $polyupaths-orange-color;
  }

  &.error {
    border: 2px solid $polyupaths-red-color;
  }

  &.draggable {
    cursor: move;
  }

  &.isDragging {
    opacity: 0.5;
  }
}

.activityDisplayIndex {
  font-size: $smaller-body-font-size;
  line-height: 20px;
  float: left;
}

.activityName {
  font-size: $smaller-body-font-size;
  line-height: 20px;
  min-height: 20px;
  overflow: hidden;
  float: left;

  span {
    display: block;
  }

  &.draggable {
    &::before {
      content: "";
      float: left;
      width: 18px;
      height: 10px;
      position: relative;
      top: 5px;
      background: url("../../images/activity_drag.svg") center / cover no-repeat;
    }

    span {
      margin-left: 22px;
    }
  }

  &.active {
    @include font-semibold;
  }
}

.activityType {
  font-size: $smallest-body-font-size;
  line-height: 14px;
  letter-spacing: 0.37px;
  margin-top: 5px;
  float: right;
}

.dragIcon {
  float: left;
  margin-right: 8px;
  margin-top: 5px;
}
