@import "../../base.module.scss";

.image {
  background-color: black;
}

.closeButton {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  border-radius: 18px;
  background: url("../../images/modal_close.svg") center / 16px auto no-repeat,
    white;
  z-index: $zIndex-modal + 1;
  cursor: pointer;
}
