@import "../../base.module.scss";

.main {
  &::before {
    content: "";
    display: block;
    height: 8px;
    background-color: $polyupaths-green-color;
  }
}

.created {
  @include layout-desktop {
    background: url("../../images/app_logo.svg") center / auto 245px no-repeat;
  }
  @include layout-mobile {
    background: url("../../images/app_logo.svg") center / auto 209px no-repeat;
  }
}

.endedMessageText {
  @include font-medium;
  font-size: 32px;
  text-align: center;
  padding: 78px 0 34px 0;
  letter-spacing: 4.5px;
  border-top: 8px solid $polyupaths-green-color;
}

.closeSessionButtonContainer {
  display: block;
  position: relative;
  @include layout-desktop {
    width: 336px;
    margin: 0 auto;
  }
  @include layout-mobile {
    padding: 0 20px;
  }
}

.closeSessionButton {
  width: 100%;
}

.closeSessionButtonText {
  display: block;
  @include font-medium;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
}
