@import "../../base.module.scss";

.templateRow {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 0 39px;
  border-bottom: 1px solid $polyupaths-gray-color;
  cursor: pointer;
}

.customTemplateRow {
  background-color: $polyupaths-gray-color3;
}

.displayCode {
  @include column-width(1);
  @include font-semibold;
  @include single-line-truncate;
  float: left;
  font-size: $small-body-font-size;
  line-height: 74px;
}

.templateInfo {
  @include max-column-width(4);
  @include min-column-width(2);
  float: left;
  margin-top: 16px;
  margin-bottom: 16px;
}

.name {
  @include font-medium;
  font-size: $large-body-font-size;
  line-height: 25px;
}

.numberOfActivities {
  color: $polyupaths-gray-color4;
  font-size: $small-body-font-size;
  line-height: 17px;
}

.arrowIcon {
  margin-left: 5px;
}

.buttons {
  float: right;
  margin-top: 19px;
  margin-bottom: 19px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}

.viewTemplateIconButton {
  float: left;
  margin-right: 15px;
  background-color: $polyupaths-green-color;
  border: none;
  @include hover-background-color-darken($polyupaths-green-color);
}

.moreButton {
  float: left;
  padding: 5px 9px 6px 9px;
  position: relative;
  right: -9px;
}

.moreButtonIcon {
  position: relative;
}

.moreActionsPopupMenu {
  position: absolute;
  right: 0;
  top: 15px;
  z-index: $zIndex-popup;
  background-color: #ffffff;
  border: 1px solid $polyupaths-gray-color;
}

.popupMenuItem {
  color: $polyupaths-black-color;
  border-radius: 0;
  border: 0;
  padding: 15px 17px;
  height: auto;
}

.createTemplateButton {
  margin-right: 15px;
}

.importedTemplateAdditionInfo {
  display: inline-block;
  position: relative;
  padding-right: 13px;

  &.expanded::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include make-triangle(top, 10px, 7px, $polyupaths-gray-color4);
  }
  &.collapsed::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @include make-triangle(bottom, 10px, 7px, $polyupaths-gray-color4);
  }
}

.disabledButton {
  opacity: 0.4;
  cursor: not-allowed;
}
