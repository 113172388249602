@import "../../base.module.scss";

$question-container-padding: 20;
$root-container-padding: 26;
$content-part-margin: 20;
$horizontal-padding: 50px;

.rootContainer {
  padding: $root-container-padding * 1px 0;
}

.questionContainer {
  @include clearfix;
  background-color: $polyupaths-gray-color3;
  border-radius: 50px;
  padding: $question-container-padding * 1px $horizontal-padding;
}

.questionContentContainer {
  overflow-x: hidden;
  overflow-y: auto;
}

.questionImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.questionText {
  font-size: 24px;
  line-height: 33px;
  overflow: hidden;
  text-align: center;
  padding: 0 34px;
  &.withImage {
    background: url("../../images/answer_image_attachment.svg") left
      (
         (33px - 18px) / 2
      ) / auto 18px no-repeat;
    cursor: pointer;
  }
}

.qaAnswerItemContainer {
  display: block;
  background-color: white;
  border: 1px solid $polyupaths-gray-color2;
  padding: 13px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 221px;
  cursor: pointer;
}

.qaAnswerItemNameText {
  font-size: 18px;
  padding-left: 28px;
  color: $polyupaths-green-color;
  background: url("../../images/green_participant.svg") center left / contain
    no-repeat;
}

.qaAnswerItemAnswerText {
  display: block;
  font-size: 22px;
  padding-top: 15px;
  padding-bottom: 36px;
  color: $polyupaths-black-color;
  white-space: pre-wrap;
}

.qaAnswerItemDeleteButton {
  position: absolute;
  display: block;
  bottom: 18px;
  right: 32px;
  width: 38px;
  height: 38px;
  background: url("../../images/trash_can_gray.svg") center / 18px auto
    no-repeat;
}

.qaAnswerListContainer {
  margin-top: $content-part-margin * 1px;
  background-color: $polyupaths-gray-color3;
  padding: 6px;
  position: relative;
}

.qaAnswerList {
  @include clearfix;
  display: block;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.qaAnswerListItem {
  position: relative;
  float: left;
  display: block;
  width: 33.33333333%;
  padding: 10px;
  cursor: pointer;
}

.qaAnswerItemPagerModal {
  display: block;
  position: absolute;
  top: 50%;
  width: 781px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: white;
}

.qaAnswerItemPagerModalContent {
  width: 100%;
  height: 100%;
}

.qaAnswerItemPagerModalNameText {
  font-size: 24px;
  color: $polyupaths-green-color;
  line-height: 33px;
  padding: 40px 50px 30px 94px;
  background: url("../../images/green_participant.svg") 50px 40px / 32px auto
    no-repeat;
}

.qaAnswerItemPagerModalAnswerText {
  font-size: 26px;
  white-space: pre-wrap;
  height: 320px;
  padding: 0 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

.qaAnswerItemPagerModalFooter {
  @include clearfix;
}

.qaAnswerItemPagerModalIndexText {
  @include font-semibold;
  overflow: hidden;
  font-size: 22px;
  color: $polyupaths-gray-color2;
  line-height: 110px;
  padding: 0 50px;
}

.qaAnswerItemPagerModalDeleteButton {
  float: right;
}

.qaAnswerItemPagerModalDeleteButtonText {
  @include font-medium;
  color: $polyupaths-red-color;
  display: block;
  font-size: 14px;
  line-height: 110px;
  padding: 0 35px 0 56px;
  background: url("../../images/trash_can.svg") 34px center / 14px auto
    no-repeat;
}

.qaAnswerItemPagerModalCloseButton {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 44px;
  height: 44px;
  background: url("../../images/modal_close.svg") center / 16px auto no-repeat;
  cursor: pointer;
}

.pollingChartContainer {
  margin-top: $content-part-margin * 1px;
  background-color: $polyupaths-gray-color3;
  overflow-x: hidden;
  overflow-y: auto;
}

.pollingChart {
  padding: 42px $horizontal-padding;
}

.whiteboardAnswerListContainer {
  position: relative;
  margin-top: $content-part-margin * 1px;
  background-color: $polyupaths-gray-color3;
  padding: 6px;
}

.whiteboardAnswerList {
  @include clearfix;
  display: block;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.whiteboardAnswerListItem {
  float: left;
  display: block;
  width: 33.33333333%;
  padding: 10px;
  cursor: pointer;
}

.whiteboardAnswerItemImage {
  position: relative;
  background-color: white;
  border: 1px solid $polyupaths-gray-color2;
}

.whiteboardAnswerItemNameText {
  position: absolute;
  left: 10px;
  top: 10px;
  max-width: 80%;

  color: $polyupaths-green-color;
  font-size: 18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border-radius: 9999px;
  padding: 13px 16px 13px 45px;
  background: url("../../images/green_participant.svg") 16px center / 20px auto
      no-repeat,
    $polyupaths-gray-color3;
}

.whiteboardAnswerItemDeleteButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 34px;
  height: 34px;
  background: url("../../images/trash_can_gray_b.svg") center / 17px auto
    no-repeat;
}

.whiteboardAnswerItemPagerModal {
  display: block;
  position: absolute;
  top: 50%;
  width: 781px;
  height: 656px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: white;
}

.whiteboardAnswerItemPagerModalContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.whiteboardAnswerItemPagerModalNameText {
  font-size: 24px;
  color: $polyupaths-green-color;
  line-height: 72px;
  padding: 0 50px 0 95px;
  background: url("../../images/green_participant.svg") 50px center / 32px auto
    no-repeat;
}

.whiteboardAnswerItemPagerModalImage {
  display: block;
  background-color: black;
  border: 1px solid $polyupaths-gray-color2;
}

.whiteboardAnswerItemPagerModalFooter {
  @include clearfix;
}

.whiteboardAnswerItemPagerModalIndexText {
  overflow: hidden;
  @include font-medium;
  color: $polyupaths-gray-color2;
  font-size: 22px;
  line-height: 72px;
  padding: 0 50px;
}

.whiteboardAnswerItemPagerModalDeleteButton {
  float: right;
}

.whiteboardAnswerItemPagerModalDeleteButtonText {
  @include font-medium;
  display: block;
  font-size: 14px;
  color: $polyupaths-red-color;
  line-height: 72px;
  padding: 0 50px 0 71px;
  background: url("../../images/trash_can.svg") 50px center / 14px auto
    no-repeat;
}

.whiteboardAnswerItemPagerModalCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
  background: url("../../images/modal_close.svg") center / 16px auto no-repeat;
  cursor: pointer;
}

/* stylelint-disable */
:export {
  rootcontainerpadding: $root-container-padding;
  questioncontainerpadding: $question-container-padding;
  contentpartmargin: $content-part-margin;
}
/* stylelint-enable */

.noAnswersText {
  @include horizontal-vertically-center-child-element;
  color: $polyupaths-gray-color4;
  font-size: 24px;
}
