@import "../../base.module.scss";

.titleText {
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6;
  margin-bottom: 10px;
}

.answerContainer {
  background-color: #ffffff;
  padding: 10px 25px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.deleteButtonContainer {
  @include clearfix;
}

.qaAnswerItemDeleteButton {
  float: right;
  width: 34px;
  height: 34px;
  background: url("../../images/trash_can_gray.svg") center / 18px auto
    no-repeat;
}
