@import "../../variables.module.scss";

.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-modal;
}
