@import "../../base.module.scss";

.titleText {
  @include font-semibold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.6;
  margin-bottom: 10px;
}

.answersContainer {
  @include clearfix;
  margin: 0 -5px 0 -5px;
  padding: 5px;
}

.answerItem {
  display: block;
  float: left;
  width: 33.333333%;
  padding: 5px;
  cursor: pointer;
}

.answer {
  background-color: white;
  border: 1px solid $polyupaths-gray-color2;
  display: block;
}

.pagerModal {
  width: 66.666666%;
  height: 530px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.whiteboardAnswerItemDeleteButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 34px;
  height: 34px;
  background: url("../../images/trash_can_gray_b.svg") center / 17px auto
    no-repeat;
}
