@import "../../base.module.scss";

$red: #fb4c69;

.createdBottomContainer {
  @include clearfix;
  position: relative;
}

.numberOfParticipantsContainer {
  float: left;
}

.numberOfParticipantsLabel {
  @include font-medium;
  @include clearfix;
  font-size: 24px;
}

.numberOfParticipantsBottomContainer {
  @include clearfix;
}

.numberOfParticipantsIcon {
  float: left;
  width: 51px;
  height: 51px;
  margin: 11.5px 9px 11.5px 0;
}

.numberOfParticipantsCount {
  @include font-light;
  float: left;
  font-size: 64px;
}

.stateCreatedContainer {
  position: relative;
}

.stateCreatedContentContainer {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.participation {
  @include font-medium;
  font-size: 42px;
  margin-bottom: 27px;
  text-align: center;
}

.participationLink {
  color: $red;
}

.sessionCodeContainer {
  text-align: center;
}

.sessionCode {
  @include font-semibold;
  display: inline-block;
  text-align: center;
  padding: 32px;
  margin: 0 auto 36px auto;
  border: 1px solid #cdcdcd;
  border-radius: 9px;
  color: $red;
  font-size: 82px;
}

.testModeContainer {
  text-align: center;
}

.testModeIcon {
  height: 13px;
  margin-right: 10px;
}

.testModeNotice {
  @include font-semibold;
  display: inline-block;
  color: white;
  padding: 0 32px;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  background-color: #f8ab45;
  border-radius: 24px;
}

.startButton {
  @include font-semibold;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 27px;
  height: 65px;
  width: 279px;
  border-radius: 32.5px;
  line-height: 65px;
  background-color: #fb4c69;
  text-align: center;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    background: url("../../images/teacher_session_start.svg") center / cover
      no-repeat;
  }
}

.endedContainer {
  position: relative;
}

.endedContentContainer {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.endedMessageText {
  @include font-medium;
  font-size: 52px;
  letter-spacing: 7.5px;
  padding-bottom: 32px;
  text-align: center;
}

.closeSessionButton {
  display: block;
  width: 279px;
  margin: 0 auto;
}

.closeSessionButtonText {
  @include font-medium;
  font-size: 27px;
  line-height: 65px;
}
