@import "../../../base.module.scss";

.dashboardContainer {
  @include row;
  background-color: #ffffff;
  // 48px is a reserved space for popup menu won't covered by footer
  padding-bottom: $dashboard-footer-height + 48px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
  position: relative;
}
