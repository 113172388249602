@import "../../base.module.scss";

$header-body-font-size: 16px;

.headerContainer {
  @include row;
  margin-left: 0;
  margin-right: 0;
  padding: 25px 39px;
  border-bottom: 1px solid $polyupaths-gray-color;
}

.formFilters {
  float: left;
}

.formFilter {
  @include font-regular;
  font-size: $header-body-font-size;
  color: black;
  letter-spacing: 0.6px;
  line-height: 30px;
  padding: 5px 25px;
  margin-right: 6px;
  background-color: #ffffff;
  border: 1px solid $polyupaths-gray-color;
  border-radius: 22px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    background-color: darken(#ffffff, 5%);
  }
}

.activeFormFilter {
  color: white;
  background-color: $polyupaths-red-color;
  &:hover {
    background-color: $polyupaths-red-color;
  }
}

.selectorInputContainer {
  float: right;
}

.selectorContainer {
  float: left;
  position: relative;

  &::after {
    content: "";
    @include make-triangle(bottom, 10px, 6px, #333333);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.selectorLabel {
  @include font-regular;
  font-size: $header-body-font-size;
  line-height: 22px;
  padding: 9px 0;
  float: left;
  margin-right: 9px;
}

.selector {
  font-size: $header-body-font-size;
  border: 0;
  height: 40px;
  padding: 8px 16px;
  min-width: 240px;
  border-radius: 20px;
  background-color: $polyupaths-gray-color;
  float: left;

  /* reset */
  appearance: none;
}

.searchInputContainer {
  float: right;
  height: 40px;
  border-radius: 22px;
  border: 1px solid #cdcdcd;
  padding: 10px;
  margin-left: 25px;
}

.searchIcon {
  vertical-align: middle;
}

.searchInput {
  line-height: 18px;
  border: 0;
  padding: 0 0 0 5px;
  font-size: $header-body-font-size;
  width: auto;
}

.selectOptionText {
  line-height: 24px;
  @include single-line-truncate;
}
