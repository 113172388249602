@import "../../base.module.scss";

$padding-top: 30px;

.background {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #cbf3ff, #f6fdff) #cbf3ff;
}

.contentContainerContainer {
  position: relative;
  padding-top: $padding-top;
}

.contentContainer {
  @include content-container;
  position: relative;
  padding: 35px 50px 32px 50px;
  background-color: white;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
  border-top: 8px solid $polyupaths-red-color2;
}
