@import "../../base.module.scss";

.datePickerContainer {
  position: relative;
}

.reactDatePicker {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
